import { useHistory } from "react-router-dom";
import { useSearch } from "../../hooks/useSearch";


import style from "./style.module.scss";

function LocationBanner() {
  const { setSearch } = useSearch();
  const history = useHistory();

  return (
    <div className={style.banner}>
      <h1>Conheça as chácaras e praias disponíveis para alugar e convide toda a galera!</h1>
      <button
        onClick={() => {
          setSearch((old) => ({
            ...old,
            spaceType: ["chacara", "praia"],
          }));

          history.push("/search");
        }}
      >
        SAIBA MAIS
      </button>
    </div>
  );
}

export default LocationBanner;
