import { createContext, useState, useEffect } from "react";
import { useHttp } from "../hooks/useHttp";

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const { request } = useHttp();

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null || localStorage.getItem("@dream/token"));
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  async function handleSignIn (body) {
    const { data } = await request("/auth/signin", false, "POST", body);

    if (data.data.status) {
      setToken(data.data.info.token)
      setUser(data.data.info.user)
      setIsLoggedIn(true)

      localStorage.setItem("@dream/token", data.data.info.token)
      localStorage.setItem("@dream/user", JSON.stringify(data.data.info.user))
    }

    return data;
  }

  async function handleSignOut () {
    setToken(null)
    setUser(null)
    setIsLoggedIn(false)

    localStorage.removeItem("@dream/token")
    localStorage.removeItem("@dream/user")
  }

  useEffect(() => {
    const token = localStorage.getItem("@dream/token")
    const user = localStorage.getItem("@dream/user")

    if (token && user) {
      setToken(token)
      setUser(JSON.parse(user))
      setIsLoggedIn(true)
    } else if (!token && user || !user && token) {
      setToken(null)
      setUser(null)
      setIsLoggedIn(false)

      localStorage.removeItem("@dream/token")
      localStorage.removeItem("@dream/user")
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        handleSignIn,
        handleSignOut,
        user,
        setUser,
        token,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
