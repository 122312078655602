import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";

import { AiOutlineUser } from "react-icons/ai";
import { IoBagOutline } from "react-icons/io5";
import { Modal } from "../Modal";

import style from "./style.module.scss";
import { useSearch } from "../../hooks/useSearch";

export function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { search, setSearch } = useSearch();

  const { isLoggedIn, user, handleSignOut } = useAuth();

  return (
    <>
      <div className={style.navbarItems}>
        <div className={style.items}>
          <span
            className={
              location.pathname === "/search"
                ? `${style.item} ${style.itemActive}`
                : style.item
            }
            onClick={() => history.push("/search")}
          >
            espaços
          </span>
          <span
            className={
              location.pathname === "/recommendation"
                ? `${style.item} ${style.itemActive}`
                : style.item
            }
          >
            Indique-nos
          </span>
        </div>
      </div>
      <div className={style.navbarActions}>
        {isLoggedIn ? (
          <div className={style.loginInfo}>
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className={style.profilePicture}
            >
              {!user.picture ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    borderRadius: "100%",
                    backgroundColor: "#00c2ff",
                  }}
                >
                  <AiOutlineUser
                    style={{
                      width: "60%",
                      height: "60%",
                    }}
                    color="#fff"
                  />
                </div>
              ) : (
                <img src={user && user.picture} />
              )}
              {isOpen && (
                <Modal
                  modalPosition="nextToItem"
                  modalSize="tiny"
                  handleClose={() => setIsOpen((old) => !old)}
                >
                  <div className={style.userOptions}>
                    <button onClick={() => history.push("/edit-profile")}>
                      Editar perfil
                    </button>
                    <button onClick={() => history.push("/my-requests")}>
                      Meus pedidos
                    </button>
                    <button
                      onClick={() => {
                        handleSignOut();

                        if (location.pathname != "/") {
                          history.push("/");
                          window.location.reload();
                        } else {
                          window.location.reload();
                        }
                      }}
                    >
                      Sair
                    </button>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        ) : (
          <>
            <span
              className={style.actions}
              onClick={() => {
                history.push("/signin");
              }}
            >
              entrar
            </span>
            <span
              onClick={() => {
                history.push("/signup");
              }}
              className={style.actions}
            >
              cadastre-se
            </span>
          </>
        )}
        <span
          style={{
            borderLeft: "2px solid #00C2FF",
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            history.push("/choice");
          }}
          className={style.actions}
        >
          <IoBagOutline size="26" style={{ color: "#000" }} />
        </span>
      </div>
    </>
  );
}
