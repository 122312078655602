import { useEffect, useState, useRef, useCallback } from "react";
import Navbar from "../../components/Navbar";

import { RatingComponent } from "../../components/RatingComponent";
import { ShareModal } from "../../components/ShareModal";
import { BsUpload, BsFillCheckCircleFill } from "react-icons/bs";
import { TiArrowSortedDown } from "react-icons/ti";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";
import Slider from "react-slick";
import { MdOutlineBlock } from "react-icons/md";
import {
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import Footer from "../../components/Footer";

import api from "../../service";

import { useHistory } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import { BalloonWrapper } from "../../components/BalloonWrapper";
import { CalendarComponent } from "../../components/CalendarComponent";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";

import style from "./style.module.scss";
import "../../utils/scss/slider.scss";
import "../../utils/scss/mui.scss";
import { useAuth } from "../../hooks/useAuth";
import { useReservations } from "../../hooks/useReservations";

function Buffet() {
  const calendarRef = useRef(null);
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 930 });
  const { isLoggedIn } = useAuth();
  const { newReservation, setNewReservation } = useReservations();
  const { id } = useParams();

  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModals, setShowModals] = useState({
    shareModal: false,
    errorModal: false,
  });
  const [availablePeriods, setAvailablePeriods] = useState([
    "morning",
    "afternoon",
    "night",
  ]);
  const [openBarWorks, setOpenBarWorks] = useState([]);
  const [services, setServices] = useState([]);
  const [capacities, setCapacities] = useState([]);
  const [specialMenus, setSpecialMenus] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [, setSelectedPackageServices] = useState([]);
  const [, setSelectedPackageProviders] = useState([]);
  const [calendarValue] = useState(new Date());
  const [buffet, setBuffet] = useState({});

  const addOrRemovePackage = (p) => {
    let latestReservation = newReservation;

    if (!p) {
      latestReservation = {
        ...latestReservation,
        package: {},
        packageServices: [],
        packageProviders: [],
      };

      setNewReservation(latestReservation);
      setSelectedPackageServices([]);
      setSelectedPackageProviders([]);

      return
    }

    if (newReservation?.package?.id === p._id) return;

    latestReservation.package = {};
    latestReservation.package.value = p.value;
    latestReservation.package.id = p._id;
    latestReservation.package.name = p.name;
    latestReservation.package.description = p.description;
    latestReservation.additionalServices = {
      additional_services_value: 0,
      items: [],
    };
    latestReservation.additionalProviders = {
      additional_providers_value: 0,
      items: [],
    };

    setSelectedPackageServices([]);
    setSelectedPackageProviders([]);

    setNewReservation({ ...latestReservation });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const formatDate = (value) => {
    let currentDate = new Date(value);

    var month = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ][currentDate.getMonth()];
    var year = currentDate.getFullYear();

    return month + " de " + year;
  };

  useEffect(() => {
    setNewReservation({
      additionalServices: {
        additional_services_value: 0,
        items: []
      },
      additionalProviders: {
        additional_providers_value: 0,
        items: []
      }
    })
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.consumer.buffets.getCapacity();

      const buffetCapacities = data.data.filter((capacity) => {
        return capacity;
      });

      buffetCapacities.sort((a, b) => {
        return a.min - b.min;
      });

      const buffetCapacityIndex = buffetCapacities.findIndex(
        (capacity) => capacity._id === buffet.capacity
      );

      const buffetCapacitiesFiltered = buffetCapacities.slice(
        0,
        buffetCapacityIndex + 1
      );

      setCapacities(buffetCapacitiesFiltered);
    })();
  }, [buffet]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar((old) => !old);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!id) history.push("/");

        localStorage.setItem("reservation_buffet_id", id);

        let data = await api.consumer.buffets.getBuffetById(id);

        if (!data.data) history.push("/");

        setBuffet(data.data.data);

        setIsLoading(false);
      } catch (error) {
        history.push("*");
      }
    })();
  }, []);

  const picturesForSlider = (array) => {
    if (!array) return;

    array.map((picture) => {
      let fromIndex = 0;

      if (picture.fav) fromIndex = array.indexOf(picture);

      const toIndex = 0;

      const element = array.splice(fromIndex, 1)[0];

      array.splice(toIndex, 0, element);
    });
  };

  picturesForSlider(buffet.pictures);

  const handleOrderValue = (total, discount) => {
    return total - (discount / 100) * total;
  };

  function handleUnavailableDays({ date }) {
    let unavailableDays = [];

    const eventDays = buffet.events.map((event) => ({
      date: event.event_date,
      period: event.event_hour,
    }));

    let buffetPeriods = Object.keys(buffet.event_period).map((k) => ({
      value: buffet.event_period[k],
      label: k,
    }));

    eventDays.forEach((event) => {
      buffetPeriods = buffetPeriods.filter(
        (period) => period.value && event.period !== period.label
      );

      if (buffetPeriods.length === 0) unavailableDays.push(event.date);
    });

    if (unavailableDays.includes(date.getTime())) {
      return true;
    } else {
      if (date.getTime() < new Date().getTime() && date.getDate() !== new Date().getDate()) {
        return true;
      } else {
        return false;
      }
    }
  }

  function handleAvailablePeriods() {
    if (!buffet.event_period) return;

    const eventDays = buffet.events.map((event) => ({
      date: event.event_date,
      period: event.event_hour,
    }));

    let buffetPeriods = Object.keys(buffet.event_period).map((k) => ({
      value: buffet.event_period[k],
      label: k,
    }));

    buffetPeriods = buffetPeriods.filter((period) => period.value);

    if (!eventDays.length) {
      setAvailablePeriods(buffetPeriods.map((period) => period.label));
    }


    eventDays.forEach((event) => {
      if (
        new Date(newReservation?.date).getTime() ===
        new Date(event.date).getTime()
      ) {
        buffetPeriods.forEach((period) => {
          if (period.value && event.period !== period.label) {
            setAvailablePeriods((old) => {
              if (old.includes(period.label)) {
                return old;
              } else {
                return [...old, period.label];
              }
            });
          } else {
            let index = availablePeriods.indexOf(period.label);
            if (index !== -1) {
              setAvailablePeriods((old) => old.splice(index, 1));
            }
          }
        });
      } else {
        setAvailablePeriods(buffetPeriods.map((period) => period.label));
      }
    });

    if (new Date(newReservation?.date).getDate() === new Date().getDate()) {
      let hour = new Date().getHours()
      let availablePeriodsTemp = []

      if (hour >= 6 && hour <= 12) {
        availablePeriodsTemp = availablePeriods.filter(i => i !== 'morning')
        setAvailablePeriods(availablePeriodsTemp)
      } else if (hour > 12 && hour <= 18) {
        availablePeriodsTemp = availablePeriods.filter(i => i !== 'morning' && i !== 'afternoon')
        setAvailablePeriods(availablePeriodsTemp)
      } else {
        availablePeriodsTemp = availablePeriods
        setAvailablePeriods(availablePeriodsTemp)
      }
    }
  }

  useEffect(() => {
    handleAvailablePeriods();
  }, [buffet, newReservation?.date]);

  useEffect(() => {
    (async () => {
      try {
        const data = await api.consumer.buffets.getServices();

        const filteredServices = data?.data?.data.filter((service) =>
          buffet?.service?.includes(service._id)
        );

        setServices(filteredServices);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [buffet]);

  useEffect(() => {
    (async () => {
      try {
        const data = await api.consumer.buffets.getSpecialMenus();

        const filteredOptions = data?.data?.data.filter((menu) =>
          buffet?.special_menu?.includes(menu._id)
        );

        setSpecialMenus(filteredOptions);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [buffet]);

  useEffect(() => {
    (async () => {
      try {
        const data = await api.consumer.buffets.getOpenBarWorks();

        const filteredOpenBarWorks = data?.data?.data.filter((openBarWork) =>
          buffet?.open_bar?.includes(openBarWork._id)
        );

        setOpenBarWorks(filteredOpenBarWorks);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [buffet]);

  const displayOpenBarWorks = useCallback(
    () =>
      openBarWorks?.map((openBarWork, index) => (
        <div className={style.display} key={index}>
          <BsFillCheckCircleFill
            color={"#00C2FF"}
            size={25}
          />
          <strong>{openBarWork.name}</strong>
        </div>
      )),
    [openBarWorks]
  );

  const displaySpecialMenus = useCallback(
    () =>
      specialMenus?.map((menu, index) => (
        <div className={style.display} key={index}>
          <BsFillCheckCircleFill
            color={"#00C2FF"}
            size={25}
          />
          <strong>{menu.name}</strong>
        </div>
      )),
    [specialMenus]
  );

  const displayServices = useCallback(
    () =>
      services?.map((service, index) => (
        <div className={style.display} key={index}>
          <BsFillCheckCircleFill
            color={"#00C2FF"}
            size={25}
          />
          <strong>{service.name}</strong>
        </div>
      )),
    [services]
  );

  return (
    <div>
      <Navbar />

      <svg width="0" height="0">
        <linearGradient id="half-star-color">
          <stop stopColor="yellow" offset="50%" />
          <stop stopColor="#e5e5e5" offset="50%" />
        </linearGradient>
      </svg>

      {showModals.shareModal && (
        <ShareModal
          handleClose={() => {
            setShowModals((old) => ({
              ...old,
              shareModal: false,
            }));
          }}
        />
      )}

      {isLoading ? (
        <div className={style.loadingPage}>
          <Spinner
            customStyles={{
              borderTopColor: "#00C2FF",
            }}
          />
        </div>
      ) : (
        <>
          <section className={style.section}>
            <div className={style.sectionHeader}>
              <h1 className={style.title}>{buffet.name}</h1>
              <div className={style.headerContent}>
                <span
                  onClick={() =>
                    setShowModals((old) => ({
                      ...old,
                      shareModal: true,
                    }))
                  }
                >
                  <BsUpload size="1.6rem" />
                  <p>{!isMobile && "Compartilhar"} </p>
                </span>
              </div>
            </div>
            <div className={style.sectionContent}>
              <div className={style.score}>
                <RatingComponent value={buffet?.opinion_score} readOnly />
                <span className={style.buffetAddressInfo}>
                  {buffet.opinions.length} -{" "}
                  {`${buffet.address.city}, ${buffet.address.state}, ${buffet.address.country}`}
                </span>
              </div>
            </div>
            <div className={style.info}>
              <div className={style.images}>
                <Slider {...sliderSettings}>
                  {buffet.pictures.map((p) => (
                    <div key={p._id}>
                      <img className={style.sliderImg} src={p.data} />
                    </div>
                  ))}
                </Slider>
                {!isMobile && (
                  <div className={style.descriptionContainer}>
                    <h2>Descrição</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: buffet.description }}
                    />
                  </div>
                )}
              </div>
              <div className={style.reservationDetails}>
                <div className={style.content}>
                  <h2>
                    A partir de{" "}
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(buffet.package_start_value)}{" "}
                    / pacote
                  </h2>
                  <div className={style.reservation}>
                    <div className={style.firstRow}>
                      <div
                        className={style.date}
                        onClick={() => setShowCalendar((old) => !old)}
                      >
                        <p>DATA</p>
                        <BalloonWrapper
                          refValue={calendarRef}
                          onClick={(e) => e.stopPropagation()}
                          open={showCalendar}
                        >
                          <CalendarComponent
                            name="first"
                            leftDir
                            rightDir
                            onClickDay={(value) => {
                              setNewReservation((old) => ({
                                ...old,
                                date: new Date(value),
                              }));
                            }}
                            tileDisabled={handleUnavailableDays}
                            tileClassName={({ date }) => {
                              if (!newReservation?.date)
                                return "calendar-tile-common";

                              if (
                                date.valueOf() ===
                                newReservation?.date.valueOf()
                              )
                                return [
                                  "calendar-tile-common",
                                  "calendar-tile-highlight",
                                ];

                              return "calendar-tile-common";
                            }}
                            value={calendarValue}
                          />
                        </BalloonWrapper>

                        <p>
                          {newReservation?.date ? (
                            newReservation?.date.toLocaleDateString("pt-BR", {
                              weekday: "short",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          ) : (
                            <i>Escolha uma data</i>
                          )}
                        </p>
                      </div>
                      <div className={style.period}>
                        <p>PERÍODO</p>
                        <Select
                          className={style.select}
                          disableUnderline
                          variant="standard"
                          value={newReservation?.hour || 0}
                          onChange={(e) =>
                            setNewReservation((old) => ({
                              ...old,
                              hour: e.target.value,
                            }))
                          }
                        >
                          <MenuItem disabled value={0}>
                            <em>Escolha um período</em>
                          </MenuItem>
                          {availablePeriods.map((p) => (
                            <MenuItem key={p} value={p}>
                              {p === "morning"
                                ? "Manhã"
                                : p === "afternoon"
                                ? "Tarde"
                                : "Noite"}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className={style.invited}>
                      <p>CONVIDADOS</p>
                      <Select
                        className={style.select}
                        disableUnderline
                        variant="standard"
                        value={newReservation?.invites || 0}
                        onChange={(e) =>
                          setNewReservation((old) => ({
                            ...old,
                            invites: e.target.value,
                          }))
                        }
                      >
                        <MenuItem disabled value={0}>
                          <em>Selecione a quantidade de convidados</em>
                        </MenuItem>
                        {capacities?.map((item) => (
                          <MenuItem key={item._id} value={item}>
                            {item.name} convidados
                          </MenuItem>
                        ))}</Select>
                    </div>
                  </div>
                  <div className={style.reservationBtn}>
                    <Button
                      text="RESERVAR"
                      onClickFunction={() => {
                        if (isLoggedIn) {
                          history.push("/payment");
                        } else {
                          setShowModals((old) => ({
                            ...old,
                            errorModal: true,
                          }));
                        }
                      }}
                      disabled={
                        newReservation?.date === undefined ||
                        newReservation?.hour === undefined ||
                        newReservation?.invites === undefined ||
                        newReservation?.package?.value === undefined ||
                        newReservation?.package?.id === undefined
                      }
                      className={
                        newReservation?.date === undefined ||
                        newReservation?.hour === undefined ||
                        newReservation?.invites === undefined ||
                        newReservation?.package?.value === undefined ||
                        newReservation?.package?.id === undefined
                          ? `${style.btn} ${style.btnDisabled}`
                          : style.btn
                      }
                    />
                  </div>
                  <p className={newReservation?.hour}>
                    {newReservation?.hour ? (
                      <>
                        O período da{" "}
                        {newReservation?.hour === "morning" ? (
                          <span>Manhã</span>
                        ) : newReservation?.hour === "afternoon" ? (
                          <span>Tarde</span>
                        ) : (
                          <span>Noite</span>
                        )}{" "}
                        para este estabelecimento está definido das{" "}
                        <span style={{ color: '#00c2ff' }}>
                          {
                            buffet?.event_period_hour[newReservation?.hour]
                              ?.start
                          }
                        </span>{" "}
                        às{" "}
                        <span style={{ color: '#00c2ff' }}>
                          {buffet?.event_period_hour[newReservation?.hour]?.end}
                        </span>
                      </>
                    ) : (
                      "Você ainda não será cobrado."
                    )}
                  </p>
                  {newReservation?.package?.value &&
                    newReservation?.package?.id && (
                      <div className={style.priceInfo}>
                        <h3>Informações de preço:</h3>
                        <span className={style.infoItem}>
                          <span>Pacote</span>
                          <span>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(newReservation?.package?.value)}
                            <button className={style.deleteButton} onClick={() => addOrRemovePackage()}>
                              <AiOutlineClose />
                            </button>
                          </span>
                        </span>
                        {buffet?.discount && (
                          <span className={style.infoItem}>
                            <span>Desconto</span>
                            <span>{buffet?.discount} %</span>
                          </span>
                        )}

                        {newReservation?.invites > 0 && (
                          <span className={style.infoItem}>
                            <span>Convidados</span>
                            <span>{newReservation?.invites}</span>
                          </span>
                        )}

                        {newReservation?.additionalServices?.items && (
                          <span className={style.infoItem}>
                            <span>Serviços adicionais</span>
                            <span>
                              {newReservation?.additionalServices?.items.map(
                                (service, index) => (
                                  <span key={service._id}>{
                                    newReservation?.additionalServices.items.length > 1
                                    && index !== newReservation.additionalServices.items.length - 1
                                      ? `${service.name}, `
                                      : service.name
                                  }</span>
                                )
                              )}
                            </span>
                          </span>
                        )}

                        {newReservation?.additionalProviders?.items && (
                          <span className={style.infoItem}>
                            <span>Fornecedores adicionais</span>
                            <span>
                              {newReservation?.additionalProviders?.items.map(
                                (provider, index) => (
                                  <span key={provider._id}>{
                                    newReservation.additionalProviders.items.length > 1
                                    && index !== newReservation.additionalProviders.items.length - 1
                                      ? `${provider.name}, `
                                      : provider.name
                                  }</span>
                                )
                              )}
                            </span>
                          </span>
                        )}

                        <span className={style.infoItemTotal}>
                          <span>Total</span>
                          <span>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(
                              handleOrderValue(
                                newReservation?.package?.value +
                                newReservation?.additionalServices
                                ?.additional_services_value +
                                newReservation?.additionalProviders
                                ?.additional_providers_value,
                                buffet.discount
                              ))}
                          </span>
                        </span>
                      </div>
                    )}
                </div>
              </div>
              {isMobile && (
                <div className={style.description}>
                  <h2>Informação</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: buffet.description }}
                  />
                </div>
              )}
            </div>
          </section>

          <section className={style.section}>
            {buffet.service_package.length ? (
              <div className={style.container}>
                <h2>Pacotes</h2>
                <div>
                  {buffet.service_package.map((p) => (
                    <Accordion
                      key={p._id}
                      expanded={expanded === p.name}
                      onChange={handleChange(p.name)}
                      className={`${style.displayItem} remove__mui__style ${
                        buffet.service_package.length > 1 ? style.display : ""
                      }`}
                    >
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        expandIcon={<TiArrowSortedDown size="26" />}
                      >
                        <Typography className={style.typography}>
                          {p.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={style.details}>
                        <div className={style.listItems}>
                          <div
                            className={style.description}
                            dangerouslySetInnerHTML={{
                              __html: p.description,
                            }}
                          />
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div className={style.list}>
                              <div className={style.items}>
                                <h3 className={style.title}>
                                  Serviços adicionais
                                </h3>
                                {p.services.length < 1 ? (
                                  <div className={style.noItems}>
                                    Não há serviços adicionais...
                                  </div>
                                ) : (
                                  p.services.map((service) => (
                                    <div className={style.display}>
                                      <button
                                        onClick={() => {
                                          if (p._id !== newReservation?.package?.id) addOrRemovePackage(p)

                                          setNewReservation((old) => {
                                            if (
                                              !old.additionalServices?.items?.find(
                                                (i) => i.id === service._id
                                              )
                                            )
                                              return {
                                                ...old,
                                                additionalServices: {
                                                  additional_services_value:
                                                    old.additionalServices
                                                      ?.additional_services_value +
                                                    service.value,
                                                  items: [
                                                    ...old.additionalServices
                                                      ?.items,
                                                    {
                                                      name: service.name,
                                                      value: service.value,
                                                      id: service._id,
                                                    },
                                                  ],
                                                },
                                              };

                                            return {
                                              ...old,
                                              additionalServices: {
                                                additional_services_value:
                                                  old.additionalServices
                                                    ?.additional_services_value -
                                                  service.value,
                                                items:
                                                  old.additionalServices?.items.filter(
                                                    (item) =>
                                                      item.id !== service._id
                                                  ),
                                              },
                                            };
                                          });
                                        }}
                                      >
                                        <BsFillCheckCircleFill
                                          color={
                                            newReservation?.additionalServices?.items?.find(
                                              (i) => i.id === service._id
                                            )
                                              ? "#00C2FF"
                                              : "#9a9a9a"
                                          }
                                          size={25}
                                        />
                                      </button>
                                      <strong>{service.name}</strong>
                                      <p>
                                        {new Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(service.value)}
                                      </p>
                                    </div>
                                  ))
                                )}
                              </div>
                              <div className={style.items}>
                                <h3 className={style.title}>
                                  Fornecedores adicionais
                                </h3>
                                {p.providers.length < 1 ? (
                                  <div className={style.noItems}>
                                    Não há serviços adicionais...
                                  </div>
                                ) : (
                                  p.providers.map((provider) => (
                                    <div className={style.display}>
                                      <button
                                        className="providers-button-check"
                                        onClick={() => {
                                          if (p._id !== newReservation?.package?.id) addOrRemovePackage(p)

                                          setNewReservation((old) => {
                                            if (
                                              !old.additionalProviders?.items?.find(
                                                (i) => i.id === provider._id
                                              )
                                            )
                                              return {
                                                ...old,
                                                additionalProviders: {
                                                  additional_providers_value:
                                                    old.additionalProviders
                                                      ?.additional_providers_value +
                                                    provider.value,
                                                  items: [
                                                    ...old.additionalProviders
                                                      ?.items,
                                                    {
                                                      name: provider.name,
                                                      value: provider.value,
                                                      id: provider._id,
                                                    },
                                                  ],
                                                },
                                              };

                                            return {
                                              ...old,
                                              additionalProviders: {
                                                additional_providers_value:
                                                  old.additionalProviders
                                                    ?.additional_providers_value -
                                                  provider.value,
                                                items:
                                                  old.additionalProviders?.items.filter(
                                                    (item) =>
                                                      item.id !== provider._id
                                                  ),
                                              },
                                            };
                                          });
                                        }}
                                      >
                                        <BsFillCheckCircleFill
                                          color={
                                            newReservation?.additionalProviders?.items?.find(
                                              (i) => i.id === provider._id
                                            )
                                              ? "#00C2FF"
                                              : "#9a9a9a"
                                          }
                                          size={25}
                                        />
                                      </button>
                                      <strong>{provider.name}</strong>
                                      <p>
                                        {new Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(provider.value)}
                                      </p>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                            <p className={style.packageActions}>
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(p.value)}
                              <Button
                                text="SELECIONAR"
                                type="button"
                                className={style.packageActionsBtn}
                                onClickFunction={() => addOrRemovePackage(p)}
                              />
                            </p>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            ) : null}
          </section>
          <section className={style.section}>
            <div className={style.container}>
              <h2>FAQ</h2>
              <Accordion
                expanded={expanded === "package_start_value"}
                onChange={handleChange("package_start_value")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Qual o valor mínimo para contratar um pacote?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <p className={style.value}>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(buffet.package_start_value)}
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "service"}
                onChange={handleChange("service")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Quais serviços oferece?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.listItems}>
                    <div className={style.list}>
                      <div className={style.items}>
                        <h3 className={style.title}>
                          Os serviços oferecidos aparecerão em destaque!
                        </h3>
                        <div className={style.displayInRow}>
                          {displayServices()}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "reception_venue "}
                onChange={handleChange("reception_venue ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Dispõe de locais onde celebrar a recepção?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.reception_venue ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "kitchen_reception "}
                onChange={handleChange("kitchen_reception ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Cozinha no mesmo local da recepção?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.kitchen_reception ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "menu "}
                onChange={handleChange("menu ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    O que inclui o menu?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div
                    className={style.value}
                    dangerouslySetInnerHTML={{ __html: buffet.menu }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "change_menu "}
                onChange={handleChange("change_menu ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    É possível adaptar ou alterar os menus?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.change_menu ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "custom_menu "}
                onChange={handleChange("custom_menu ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    É possível elaborar menus personalizados?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.custom_menu ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "special_menu"}
                onChange={handleChange("special_menu")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Dispõe de menus especiais?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.listItems}>
                    <div className={style.list}>
                      <div className={style.items}>
                        <h3 className={style.title}>
                          Os menus especiais oferecidos aparecerão em destaque!
                        </h3>
                        <div className={style.displayInRow}>
                          {displaySpecialMenus()}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "cakes_candies "}
                onChange={handleChange("cakes_candies ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Também serve bolos e doces?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.cakes_candies ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "own_cakes_candies "}
                onChange={handleChange("own_cakes_candies ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Posso levar meu próprio bolo e/ou doce?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.own_cakes_candies ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "open_bar"}
                onChange={handleChange("open_bar")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Como funciona o Open bar?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.listItems}>
                    <div className={style.list}>
                      <div className={style.items}>
                        <h3 className={style.title}>
                          Os modos que indicam como funciona aparecerão em
                          destaque!
                        </h3>
                        <div className={style.displayInRow}>
                          {displayOpenBarWorks()}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "photographer_exclusivity "}
                onChange={handleChange("photographer_exclusivity ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Exclusividade de fotógrafo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.photographer_exclusivity ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "music_exclusivity "}
                onChange={handleChange("music_exclusivity ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Exclusividade de música?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.value}>
                    {buffet.music_exclusivity ? "Sim" : "Não"}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "event_period "}
                onChange={handleChange("event_period ")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Em quais períodos você celebra seus eventos?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <div className={style.listItems}>
                    <div className={style.list}>
                      <div className={style.items}>
                        <h3 className={style.title}>
                          Os períodos disponíveis aparecerão em destaque!
                        </h3>
                        <div className={style.displayInRow}>
                          {Object.entries(buffet.event_period).map(
                            (period, index) => (
                              <div className={style.display} key={index}>
                                <BsFillCheckCircleFill
                                  color={period[1] ? "#00C2FF" : "#9a9a9a"}
                                  size={25}
                                />
                                <strong>
                                  {period[0] === "morning"
                                    ? "Manhã"
                                    : period[0] === "afternoon"
                                    ? "Tarde"
                                    : "Noite"}
                                </strong>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "minimum_penalty"}
                onChange={handleChange("minimum_penalty")}
                className={`${style.displayItem} ${style.display} remove__mui__style`}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  expandIcon={<TiArrowSortedDown size="26" />}
                >
                  <Typography className={style.typography}>
                    Qual é a penalização no caso de não se atingir o mínimo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={style.details}>
                  <p className={style.value} dangerouslySetInnerHTML={{__html: buffet.minimum_penalty}} />
                </AccordionDetails>
              </Accordion>
            </div>
          </section>

          <section className={style.section}>
            <div className={style.container}>
              <p className={style.noComments}>
                {buffet.opinions.length > 0
                  ? `${buffet.opinions.length} COMENTÁRIOS`
                  : "NENHUM COMENTÁRIO"}
              </p>
              <div className={style.comments}>
                {showAllComments
                  ? buffet.opinions.map((opinion) => (
                      <div key={opinion._id} className={style.comment}>
                        <div style={{ display: "flex" }}>
                          {opinion.people.picture ? (
                            <img
                              className={style.userImg}
                              src={opinion.people.picture}
                            />
                          ) : (
                            <div className={style.userImg}>
                              <AiOutlineUser
                                style={{
                                  width: "60%",
                                  height: "60%",
                                }}
                                color="#fff"
                              />
                            </div>
                          )}
                          <div className={style.userInfo}>
                            <p style={{ fontSize: 30 }}>
                              <strong>{opinion.people.name}</strong>
                            </p>
                            <span>
                              <strong>{formatDate(opinion.date)}</strong>
                            </span>
                            <span>
                              <strong>{opinion.event.name}</strong>
                            </span>
                          </div>
                        </div>
                        <div className={style.commentContent}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: opinion.message,
                            }}
                          />
                        </div>
                      </div>
                    ))
                  : buffet.opinions.slice(0, 6).map((opinion) => (
                      <div key={opinion._id} className={style.comment}>
                        <div style={{ display: "flex" }}>
                          {opinion.people.picture ? (
                            <img
                              className={style.userImg}
                              src={opinion.people.picture}
                            />
                          ) : (
                            <div className={style.userImg}>
                              <AiOutlineUser
                                style={{
                                  width: "60%",
                                  height: "60%",
                                }}
                                color="#fff"
                              />
                            </div>
                          )}
                          <div className={style.userInfo}>
                            <p style={{ fontSize: 30 }}>
                              <strong>{opinion.people.name}</strong>
                            </p>
                            <span>
                              <strong>{formatDate(opinion.date)}</strong>
                            </span>
                            <span>
                              <strong>{opinion.event.name}</strong>
                            </span>
                          </div>
                        </div>
                        <div className={style.commentContent}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: opinion.message,
                            }}
                          />
                        </div>
                      </div>
                    ))}
              </div>
              <div className={style.showAllCommentsBtn}>
                {buffet.opinions.length > 6 && (
                  <Button
                    text={`${
                      showAllComments ? "ESCONDER" : "MOSTRAR"
                    } TODOS OS COMENTÁRIOS`}
                    type="button"
                    className={style.btn}
                    onClickFunction={() => {
                      setShowAllComments((old) => !old);
                    }}
                  />
                )}
              </div>
            </div>
          </section>

          <Footer />
        </>
      )}

      {showModals.errorModal && (
        <Modal
          darkBackground
          hiddenOverflow
          modalPosition="centered"
          modalSize="big"
          closeIconPosition="right"
          handleClose={() => {
            setShowModals((old) => ({ ...old, errorModal: false }));
          }}
        >
          <div className={style.modalHeader}>
            <strong>Ops, algo deu errado!</strong>
          </div>
          <div className={style.modalContent}>
            <MdOutlineBlock color="#00c2ff" size="7em" />
            <p className={style.loginMsg}>
              Você precisa ter uma conta na <span>Dream Buffets</span> para ter
              uma reserva. Não possui uma conta?{" "}
              <a href="/signup">cadastre-se agora!</a>
            </p>
          </div>
          <div className={`${style.modalActions} ${style.login}`}>
            <Button
              text="FAZER LOGIN"
              type="button"
              onClickFunction={() => history.push("/signin")}
              className={style.loginBtn}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Buffet;
