import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "./style.scss";

export function ToggleButtonComponent({
  value,
  triggerToggle,
  checkValue,
  uncheckValue,
  ...rest
}) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={triggerToggle}
      {...rest}
    >
      <ToggleButton value={checkValue}>{checkValue}</ToggleButton>
      <ToggleButton value={uncheckValue}>{uncheckValue}</ToggleButton>
    </ToggleButtonGroup>
  );
}
