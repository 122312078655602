import http, { Axios } from "axios";

let baseURL = process.env.REACT_APP_API_URL + "/api";
let publicHttp = http.create({
  baseURL,
});
let privateHttp = http.create({
  baseURL,
});

export { publicHttp, privateHttp };
