import { ImQuotesLeft } from "react-icons/im";
import { AiOutlineUser } from "react-icons/ai";

import style from "./style.module.scss";

function userExperienceCard({ img, name, formattedDate, message }) {
  return (
    <div className={style.userExperienceCard}>
      <div className={style.quotesIcon}>
        <ImQuotesLeft size="24" />
      </div>
      {img ? (
        <img alt={name} className={style.cardImg} src={img} />
      ) : (
        <div alt='Imagem de usuário padrão' className={style.cardImg}>
          <AiOutlineUser
            style={{
              width: "60%",
              height: "60%",
            }}
            color="#fff"
          />
        </div>
      )}
      <div className={style.cardContent}>
        <p>{name && name}</p>
        <p>{formattedDate && formattedDate}</p>
        <div className={style.message} dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  );
}

export default userExperienceCard;
