import { useHistory } from "react-router-dom";

import EmailConfirmation from "../../components/EmailConfirmation";

import style from "./style.module.scss";
import logo from "../../assets/images/logo.png";
import sideImg from "../../assets/images/recover.png";

function NewPass() {
  const history = useHistory();

  return (
    <div className={style.container}>
      <aside
        className={style.sideImg}
        style={{ backgroundImage: `url(${sideImg})` }}
      />
      <div className={style.confirmContainer}>
        <div className={style.logo}>
          <img
            onClick={() => {
              history.push("/");
            }}
            src={logo}
          />
        </div>
        <EmailConfirmation />
      </div>
    </div>
  );
}

export default NewPass;
