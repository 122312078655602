import { RiCloseLine } from 'react-icons/ri'

import style from './style.module.scss'

export function Modal({
    children,
    modalPosition,
    modalSize,
    closeIconPosition,
    handleClose,
    darkBackground,
    hiddenOverflow,
    closeBtnStyles,
    modalStyles
  }) {
  return (
    <div className={`${
      style.container} ${
        darkBackground ? style.dark : ''
      }`}
      onClick={(e) => {
        e.stopPropagation();

        handleClose();
      }}
    >
      <div
        className={`${
          style.modal} ${modalPosition ? style[modalPosition] : '' } ${
          modalSize ? style[modalSize] : ''
        } ${hiddenOverflow ? style.hiddenOverflow : ''}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalStyles}
      >
        <div>
          {children}
          <button
            className={`${
              style.close} ${closeIconPosition === 'right'
                ? style.closeRight
                : closeIconPosition === 'left'
                ? style.closeLeft
                : ''
              }`}
            onClick={handleClose}
            style={closeBtnStyles}
          >
            <RiCloseLine size='1.3rem' />
          </button>
        </div>
      </div>
    </div>
  )
}
