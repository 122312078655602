import { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/useToast";
import { useAuth } from "../../hooks/useAuth";

import Resizer from "react-image-file-resizer";
import Navbar from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { RiPencilFill } from "react-icons/ri";
import { Button } from "@material-ui/core";
import { AiOutlineUser } from "react-icons/ai";
import ReactInputMask from "react-input-mask";
import api from "../../service";

import "./style.scss";

function EditProfile() {
  const { user, setUser, isLoggedIn } = useAuth();
  const { notify } = useToast();
  const history = useHistory();

  const isMobile = useMediaQuery({ maxWidth: 930 });

  const [profileImg, setProfileImg] = useState(null);
  const [userName, setUserName] = useState("");
  const [nameUntilChange, setNameUntilChange] = useState(userName);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [state_id, setState_id] = useState("");
  const [states, setStates] = useState([]);
  const [stateValue, setStateValue] = useState("default");
  const [cityValue, setCityValue] = useState("default");
  const [cities, setCities] = useState([]);

  function hasAllCases(string) {
    const upper = /[A-Z]/.test(string),
      lower = /[a-z]/.test(string);

    return upper && lower;
  }

  const checkPassword = (password) => {
    if (!password) return false;

    if (password.length < 6) {
      return true;
    } else if (!hasAllCases(password)) {
      return true;
    } else {
      return false;
    }
  };

  const [address, setAddress] = useState({
    code: "",
    place: "",
    number: null,
    district: "",
    complement: "",
    country: "",
  });
  const [password, setPassword] = useState("");
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  async function handleSubmitUserInformation(event) {
    event.preventDefault();

    try {
      let token = localStorage.getItem("@dream/token");

      let config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let body = {
        name: userName,
        picture: profileImg,
        email: email,
        address_code: address.code.replace(/[^0-9 ]/g, ""),
        street: address.place,
        number: address.number,
        district: address.district,
        complement: address.complement,
        city: cityValue,
        state: state_id,
        country: address.country,
        password: password,
      };

      setNameUntilChange(userName);

      let data = await api.consumer.auth.updateuser(body, config);

      if (data?.data?.data) {
        localStorage.setItem("@dream/user", JSON.stringify(data.data.data));

        setUser(data.data.data);

        notify("Sucesso!", "Perfil atualizado com sucesso!", "success");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        512,
        512,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        let token = localStorage.getItem("@dream/token");

        let config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        let { data } = await api.consumer.auth.getuser(config);

        if (data.data.email) {
          setEmail(data.data.email);
        }

        if (data.data.code) {
          setCode(data.data.code);
        }

        if (data.data.address_code) {
          setAddress((old) => ({ ...old, code: data.data.address_code }));
        }

        if (data.data.picture) {
          setProfileImg(data.data.picture);
        }

        if (data.data.name) {
          setUserName(data.data.name);
        }

        if (data.data.state) {
          setState_id(data.data.state._id);
          setStateValue(data.data.state._id + "-" + data.data.state._id);
        }

        if (data.data.city) {
          setCityValue(data.data.city._id);
        }

        if (data.data.district) {
          setAddress((old) => ({ ...old, district: data.data.district }));
        }

        if (data.data.street) {
          setAddress((old) => ({ ...old, place: data.data.street }));
        }

        if (data.data.complement) {
          setAddress((old) => ({ ...old, complement: data.data.complement }));
        }

        if (data.data.country) {
          setAddress((old) => ({ ...old, country: data.data.country }));
        }

        if (data.data.number) {
          setAddress((old) => ({ ...old, number: data.data.number }));
        }

        let localStorageUser = JSON.parse(localStorage.getItem("@dream/user"));

        if (localStorageUser !== data.data) {
          localStorage.setItem("@dream/user", JSON.stringify(data.data));
        }
      }
    })();
  }, [isLoggedIn, user]);

  useEffect(() => {
    (async () => {
      let dataState = await api.consumer.places.getState();
      setStates(dataState.data.data);
    })();
  }, []);

  useEffect(() => {
    if (!stateValue.trim().length) return;

    (async () => {
      let { data } = await api.consumer.places.getCity(
        stateValue.split(`-`)[0]
      );

      setCities(data.data);
    })();
  }, [stateValue]);

  return (
    <>
      <Navbar />
      <main className="edit-profile-content">
        {!isMobile && <Sidebar />}
        <form onSubmit={handleSubmitUserInformation} className="container">
          <div className="user-container">
            <div
              style={{
                backgroundImage: profileImg ? `url(${profileImg})` : "",
                backgroundColor: "#00C2FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="edit-profile-picture"
            >
              {!profileImg && (
                <AiOutlineUser
                  style={{
                    width: "60%",
                    height: "60%",
                  }}
                  color="#fff"
                />
              )}
              <div type="button" className="edit-image">
                <input
                  onChange={async (e) => {
                    for (let file of e.target.files) {
                      const image = await resizeFile(file);

                      setProfileImg(image);
                    }
                  }}
                  name="image-upload"
                  id="image-upload"
                  type="file"
                  style={{ display: "none" }}
                />
                <label htmlFor="image-upload">
                  <RiPencilFill size="20" />
                </label>
              </div>
            </div>
            <h5 className="user-name">{nameUntilChange}</h5>
          </div>
          <section className="section">
            <label className="input-label" htmlFor="buffet-info">
              Informações básicas
            </label>
            <input
              className="input"
              type="text"
              name="user-info"
              id="user-name"
              placeholder="Nome do usuário"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <input
              className="input"
              type="text"
              name="user-info"
              id="user-email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ReactInputMask
              className="input"
              type="text"
              name="user-info"
              id="user-code"
              placeholder="CPF"
              value={code}
              disabled
              mask="999.999.999-99"
            />
          </section>
          <section className="section">
            <label className="input-label" htmlFor="user-address">
              Endereço
            </label>
            <ReactInputMask
              className="input"
              type="text"
              name="user-address"
              id="user-address-code"
              placeholder="CEP"
              value={address.code}
              onChange={async (e) => {
                setAddress((old) => ({ ...old, code: e.target.value }));

                if (
                  e.target.value.length >= 8 &&
                  e.target.value.indexOf("_") === -1
                ) {
                  let token = localStorage.getItem("@dream/token");

                  let config = {
                    headers: { Authorization: `Bearer ${token}` },
                  };

                  let { data } = await api.consumer.listSearch.getAddressSearch(
                    e.target.value,
                    config
                  );

                  if (data.data.street) {
                    setAddress((old) => ({ ...old, place: data.data.street }));
                  }

                  if (data.data.neighborhood) {
                    setAddress((old) => ({
                      ...old,
                      district: data.data.neighborhood,
                    }));
                  }
                }
              }}
              mask="99999-999"
            />
            <input
              className="input"
              type="text"
              name="user-address"
              id="user-address-place"
              placeholder="Logradouro"
              value={address.place}
              onChange={(e) =>
                setAddress((old) => ({ ...old, place: e.target.value }))
              }
            />
            <input
              className="input"
              type="number"
              name="user-address"
              id="user-address-number"
              placeholder="Número"
              value={address.number}
              onChange={(e) =>
                setAddress((old) => ({ ...old, number: e.target.value }))
              }
            />
            <input
              className="input"
              type="text"
              name="user-address"
              id="user-address-district"
              placeholder="Bairro"
              value={address.district}
              onChange={(e) =>
                setAddress((old) => ({ ...old, district: e.target.value }))
              }
            />
            <input
              className="input"
              type="text"
              name="user-address"
              id="user-address-complement"
              placeholder="Complemento"
              value={address.complement}
              onChange={(e) =>
                setAddress((old) => ({ ...old, complement: e.target.value }))
              }
            />
            <input
              className="input"
              type="text"
              name="user-address"
              id="user-address-country"
              placeholder="País"
              value={address.country}
              onChange={(e) =>
                setAddress((old) => ({ ...old, country: e.target.value }))
              }
            />
            <select
              className="input"
              value={stateValue}
              onChange={(e) => {
                setStateValue(e.target.value);
                setState_id(e.target.value.split(`-`)[1]);
              }}
            >
              <option value="default" selected style={{ color: "#ededed" }}>
                Estado
              </option>
              {states.map((state) => (
                <option value={state._id + `-` + state._id}>
                  {state.name}
                </option>
              ))}
            </select>
            <select
              className="input"
              disabled={cities.length == 0}
              value={cityValue}
              onChange={async (e) => {
                setCityValue(e.target.value);
              }}
            >
              <option value="default" selected style={{ color: "#ededed" }}>
                Cidade
              </option>
              {cities.map((city) => (
                <option value={city._id}>{city.name}</option>
              ))}
            </select>
          </section>
          <section className="section">
            <label className="input-label" htmlFor="password">
              Nova senha
            </label>
            <span className="new-pass-instruction">
              A senha deve ser maior que 6 dígitos e deve conter letras maiúsculas e minúsculas.
            </span>
            <div className={`password-field ${checkPassword(password) ? 'error' : ''}`}>
              <input
                className={`input ${isEditingPassword ? "active" : ""}`}
                type={`${isEditingPassword ? "text" : "password"}`}
                onBlur={() => setIsEditingPassword(false)}
                onFocus={() => setIsEditingPassword(true)}
                name="password"
                id="password"
                new-password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="button">
                <RiPencilFill size="24" />
              </button>
            </div>
          </section>

          <div className="actions">
            <Button
              type="button"
              onClick={() => {
                history.push("/");
              }}
              style={{
                fontWeight: "500",
                fontFamily: "Catamaran",
                fontSize: "1.1rem",
                backgroundColor: "transparent",
                borderRadius: "16.5px",
                boxShadow: "none",
                width: "10rem",
                marginTop: "2rem",
                marginLeft: "0.4rem",
                height: "2.4rem",
                color: "#00C2FF",
                textTransform: "none",
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              disabled={password !== '' && checkPassword(password)}
              style={{
                fontWeight: "700",
                fontFamily: "Catamaran",
                fontSize: "1.1rem",
                backgroundColor: "#00C2FF",
                borderRadius: "16.5px",
                boxShadow: "none",
                width: "10rem",
                marginTop: "2rem",
                height: "2.4rem",
                color: "#FFFFFF",
              }}
            >
              Salvar
            </Button>
          </div>
        </form>
      </main>
    </>
  );
}

export default EditProfile;
