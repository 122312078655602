import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/useToast";
import { useHttp } from "../../hooks/useHttp";

import { Button } from '../Button'
import {
  TextField,
} from "@material-ui/core";

import style from "./style.module.scss";

function RecoverForm() {
  const history = useHistory()
  const { notify } = useToast()
  const { request } = useHttp()

  const [email, setEmail] = useState("");

  async function handleRecover(e) {
    e.preventDefault();

    let body = {
      email: email,
      type: 'people'
    };

    try {
      const { data } = await request('/password_recovery', false, 'POST', body);

      if (data.data.status) {
        notify('E-mail enviado com sucesso!', 'Por favor, verifique sua caixa de entrada.', 'success')
        
        history.push("/recover-password-email");
      } else {
        notify('Erro ao identificar conta', 'Nenhuma conta encontrada para esse endereço de e-mail', 'error')
      }
    } catch (e) {
      notify('Não foi possível enviar o e-mail', `${e.message}`, 'error')
    }
  }

  return (
    <div>
      <form onSubmit={handleRecover} className={style.container}>
        <h1>Entre em sua conta</h1>
        <p className={style.instruction}>Escreva seu e-mail e você receberá instruções para recuperar sua senha</p>

        <div className={style.input}>
          <TextField
            variant="standard"
            label="E-mail"
            className={style.textField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className={style.actions}>
          <Button
            type="submit"
            text="Recuperar minha senha"
            className={style.recoverBtn}
          />
        </div>
        <div className={style.login}>
          <a href='/signin'>
            Fazer login
          </a>
        </div>
      </form>
    </div>
  );
}

export default RecoverForm;
