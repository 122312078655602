import { useEffect, useState } from 'react';
import style from './style.module.scss';

export function CarouselBackground({ images, children }) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentImage, images.length]);

  return (
    <div className={style.carouselBackground}>
      <div
        className={style.carouselBackground__image}
        style={{
          backgroundImage: `url(${images[currentImage]})`,
        }}
      />
      <div className={style.carouselBackground__content}>{children}</div>
      <div className={style.carouselBackground__index}>
        {images.map((_, index) => (
          <div
            key={index}
            className={style.carouselBackground__index__item}
            style={{
              backgroundColor: index === currentImage ? '#00c2ff' : '#c4c4c4',
            }}
          />
        ))}
      </div>
    </div>
  );
}
