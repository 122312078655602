import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import style from "./style.module.scss";

function HorizontalListView({ maxLine, children }) {
  const [index, setIndex] = useState(1);
  const [lastRender, setLastRender] = useState(0);
  const maxDisplayTimes = Math.ceil(children.length / maxLine);

  const toNext = () => {
    setIndex((old) => old + 1);
    setLastRender((old) => old + maxLine);
  };

  const toBack = () => {
    setIndex((old) => old - 1);
    setLastRender((old) => old - maxLine);
  };

  const toEnd = () => {
    setIndex(maxDisplayTimes);
    setLastRender(children.length - maxLine);
  };

  const toStart = () => {
    setIndex(1);
    setLastRender(0);
  };

  return (
    <div className={style.list}>
      <FaChevronLeft
        style={{
          cursor:
            index - 1 <= maxDisplayTimes && index - 1 > 0
              ? "pointer"
              : "normal",
        }}
        onClick={index - 1 <= maxDisplayTimes && index - 1 > 0 ? toBack : toEnd}
        size={35}
        color={"#898989"}
      />

      {children.slice(lastRender, lastRender + maxLine).map((item) => item)}

      <FaChevronRight
        style={{
          cursor: index + 1 <= maxDisplayTimes ? "pointer" : "normal",
        }}
        onClick={index + 1 <= maxDisplayTimes ? toNext : toStart}
        size={35}
        color={"#898989"}
      />
    </div>
  );
}

export default HorizontalListView;
