import Core from '@8_dev/front_web_lbry'
import { useAuth } from './useAuth'

export function useHttp () {
  const apiUrl = process.env.REACT_APP_API_URL
  const { token } = useAuth()

  async function request (url, withAuth, method, body) {
    if (method.toUpperCase() === 'GET') {
      return await Core.module.server.http.get({
        url: apiUrl + '/api' + url,
        headers: {
          Authorization: withAuth && 'Barear ' + token,
          'Content-Type': 'application/json'
        }
      })
    } else if (method.toUpperCase() === 'POST') {
      return await Core.module.server.http.post({
        url: apiUrl + '/api' + url,
        body: body,
        headers: {
          Authorization: withAuth && 'Barear ' + token,
          'Content-Type': 'application/json'
        }
      })
    }
  }

  return { request }
}
