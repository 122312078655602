import { Toaster } from 'react-hot-toast'

export function Toast() {
  return (
    <Toaster
      position="bottom-right"
      toastOptions={{
        duration: 5000,
      }}
    />
  )
}
