import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

export const Map = withScriptjs(
  withGoogleMap(({ location }) => (
    <GoogleMap
      defaultZoom={14}
      center={location}
      defaultOptions={{
        disableDefaultUI: true,
      }}
    >
      <Marker position={location} />
    </GoogleMap>
  ))
);
