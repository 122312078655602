import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Logo from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { useHttp } from '../../hooks/useHttp'
import { useToast } from '../../hooks/useToast'
import style from '../../styles/clientArea.module.scss'

function DataRectify() {
  const [email, setEmail] = useState("");
  const [rectifyContent, setRectifyContent] = useState("");
  const [toBeRectifiedData, setToBeRectifiedData] = useState({
    comments: false,
    userData: false
  })
  const history = useHistory();
  const { request } = useHttp()
  const { notify } = useToast()

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { userData: user_data, comments } = toBeRectifiedData

    const { data: response } = await request('/rectify_data', true, 'POST', { rectify_content: rectifyContent, comments, user_data, email });

    if (response.status) notify('Solicitação feita com sucesso!', 'Verifique as informações no e-mail informado!', 'success')
  }

  return (
    <div
      style={{
        display: "flex",
        overflowX: "hidden",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "85%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: 200,
            cursor: "pointer",
          }}
          src={Logo}
          onClick={() => history.push("/")}
          alt="Dream Buffets logo"
        />
        <h1>Área do cliente</h1>
        <div className={style.navigationBar}>
          <span
            style={{
              borderRadius: "50px 0 0 50px",
              backgroundColor: "#fff",
              color: "#00c2ff",
            }}
            onClick={() => history.push("/client-area/data-access")}
          >
            PEDIDOS DE ACESSO A DADOS
          </span>
          <span
            style={{
              backgroundColor: "#fff",
              color: "#00c2ff",
            }}
            onClick={() => history.push("/client-area/data-forget")}
          >
            ESQUEÇA O PEDIDO DE DADOS
          </span>
          <span
            style={{
              backgroundColor: "#00c2ff",
              color: "#fff",
            }}
          >
            RETIFICAR SOLICITAÇÃO DE DADOS
          </span>
          <span
            style={{
              borderRadius: "0 50px 50px 0",
              backgroundColor: "#fff",
              color: "#00c2ff",
            }}
            onClick={() => history.push("/client-area/subscription-cancel")}
          >
            SOLICITAÇÃO DE CANCELAMENTO DE INSCRIÇÃO
          </span>
        </div>
      </div>
      <div className={style.bodyContainer}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          onSubmit={handleSubmit}
        >
          <p
            style={{
              marginBottom: 50,
              color: "#C5C5C5",
            }}
          >
            Solicite uma cópia dos dados que temos sobre você. Um e-mail será
            enviado para você com os dados depois que forem gerados.
          </p>
          <div
            style={{
              width: "48%",
              color: "#C5C5C5",
            }}
          >
            <p>
              Selecione o que você deseja ser esquecido. Você será notificado
              por e-mail assim que terminar.
            </p>

            <div className={style.formContainer}>
              <div className={style.formItem}>
                <input
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  type="checkbox"
                  id="comment"
                  onChange={() => setToBeRectifiedData({ ...toBeRectifiedData, comments: !toBeRectifiedData.comments })}
                />
                <label className="label-comment" html="comment">
                  Comentários
                </label>
              </div>
              <p
                style={{
                  fontSize: 15,
                  color: "#DBDBDB",
                  marginBottom: 10,
                }}
              >
                Seus comentários publicados nos buffets
              </p>
              <div className={style.formItem}>
                <input
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  type="checkbox"
                  id="comment"
                  onChange={() => setToBeRectifiedData({ ...toBeRectifiedData, userData: !toBeRectifiedData.userData })}
                />
                <label className="label-comment" html="comment">
                  Dados do usuário
                </label>
              </div>
              <p
                style={{
                  fontSize: 15,
                  color: "#DBDBDB",
                  marginBottom: 10,
                }}
              >
                Seus dados de usuário registrados no banco de dados
              </p>
              <textarea
                value={rectifyContent}
                placeholder="O que deve ser retificado?"
                onChange={(e) => setRectifyContent(e.target.value)}
              ></textarea>
              <TextField
                style={{
                  height: 50,
                }}
                value={email}
                className={style.inputText}
                variant="standard"
                label="E-mail"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{ style: { fontSize: 20 } }}
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: 75,
              }}
            >
              <Button
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#00C2FF",
                  borderRadius: "20px",
                  boxShadow: "none",
                  width: "130px",
                  height: "35px",
                }}
                type="submit"
                variant="contained"
              >
                Enviar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DataRectify;
