import { useState } from "react";
import {
  AiFillInstagram,
  AiOutlineInstagram,
  AiFillYoutube,
  AiOutlineYoutube,
} from "react-icons/ai";
import { FiTwitter, FiLinkedin, FiFacebook } from "react-icons/fi";
import { FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa";

import style from "./style.module.scss";
import Logo from "../../assets/images/logo.png";

function Footer() {
  let buffetAdminPath = process.env.REACT_APP_PROVIDER_PATH;

  const [hoveredIcons, setHoveredIcons] = useState({
    T: false,
    F: false,
    I: false,
    L: false,
    Y: false,
  });

  const debounce = (func, wait = 200) => {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func();
      }, wait);
    };
  };

  const handleAnimate = (setStateFunc) => debounce(setStateFunc);

  return (
    <div className={style.footer}>
      <div className={style.content}>
        <div className={style.image}>
          <img className={style.logo} src={Logo} />
        </div>
        <div className={style.card}>
          <h2>Navegue</h2>
          <a href="/">Home</a>
          <a href="/search">Espaços</a>
          <a href="">Contato</a>
        </div>
        <div className={style.card}>
          <h2>Painel administrativo</h2>
          <a href={`${buffetAdminPath}/register`}>Cadastrar meu buffet</a>
          <a href={`${buffetAdminPath}/`}>Início</a>
        </div>
        <div className={style.card}>
          <h2>Siga-nos nas redes sociais</h2>
          <div className={style.icons}>
            <div
              onClick={() =>
                window.open("https://twitter.com/DreamBuffets", "_blank")
              }
              className={style.twitterIcon}
              onMouseEnter={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, T: true })));
              }}
              onMouseLeave={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, T: false })));
              }}
            >
              {hoveredIcons.T ? (
                <FaTwitter size="22" />
              ) : (
                <FiTwitter size="24" />
              )}
            </div>
            <div
              onClick={() =>
                window.open("https://www.facebook.com/dreambuffets", "_blank")
              }
              className={style.facebookIcon}
              onMouseEnter={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, F: true })));
              }}
              onMouseLeave={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, F: false })));
              }}
            >
              {hoveredIcons.F ? (
                <FaFacebookF size="22" />
              ) : (
                <FiFacebook size="24" />
              )}
            </div>
            <div
              onClick={() =>
                window.open("https://www.instagram.com/dreambuffets", "_blank")
              }
              className={style.instagramIcon}
              onMouseEnter={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, I: true })));
              }}
              onMouseLeave={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, I: false })));
              }}
            >
              {hoveredIcons.I ? (
                <AiFillInstagram size="24" />
              ) : (
                <AiOutlineInstagram size="24" />
              )}
            </div>
            <div
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/dreambuffets",
                  "_blank"
                )
              }
              className={style.linkedinIcon}
              onMouseEnter={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, L: true })));
              }}
              onMouseLeave={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, L: false })));
              }}
            >
              {hoveredIcons.L ? (
                <FaLinkedinIn size="22" />
              ) : (
                <FiLinkedin size="24" />
              )}
            </div>
            <div
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCDT4ca2HkNNSTAgjWhfgV8A",
                  "_blank"
                )
              }
              className={style.youtubeIcon}
              onMouseEnter={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, Y: true })));
              }}
              onMouseLeave={() => {
                handleAnimate(setHoveredIcons((old) => ({ ...old, Y: false })));
              }}
            >
              {hoveredIcons.Y ? (
                <AiFillYoutube size="24" />
              ) : (
                <AiOutlineYoutube size="24" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style.termsContainer}>
        <div className={style.copyright}>
          <p
            style={{
              color: "#00C2FF",
            }}
          >
            Dream Buffets
          </p>
          <p style={{ paddingLeft: 5 }}>
            {" "}
            © 2022. Todos os direitos reservados.{" "}
          </p>
        </div>
        <div className={style.terms}>
          <a href="/use-terms">Termos de Uso</a>
          <a href="/privacy-policy">Política de Privacidade</a>
          <a href="/client-area/data-access">Área do Cliente</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
