import Rating from "@mui/material/Rating";

export function RatingComponent ({ ...rest }) {
  return (
    <Rating
      name="half-rating"
      precision={0.5}
      {...rest}
    />
  );
}
