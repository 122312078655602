import { privateHttp, publicHttp } from "./http";

export default {
  consumer: {
    auth: {
      login: async (body) => publicHttp.post("/auth/signin", body),
      updateuser: async (body, config) =>
        privateHttp.post("/update_profile", body, config),
      getuser: async (config) => privateHttp.get("people_profile", config),
      recoverPass: async (body) => publicHttp.post("/password_recovery", body),
      setNewPass: async (body) => publicHttp.post("/set_new_password", body),
    },
    people: {
      add: async (body) => publicHttp.post("/people", body),
      get: async () => publicHttp.get("/people"),
    },
    places: {
      getState: async () => publicHttp.get("/state_name"),
      getCity: async (state_id) =>
        publicHttp.get("/city_name?state=" + state_id),
      searchCity: async (search) =>
        publicHttp.get("/city_name?search=" + search),
    },
    support: {
      contactSupport: async (body, config) =>
        privateHttp.post("request_support", body, config),
    },
    reservations: {
      createReservation: async (body, config) =>
        privateHttp.post("/buffet_space_event", body, config),
      getReservations: async (config) =>
        privateHttp.get("/buffet_space_event", config),
      getReservationDetails: async (id, config) =>
        privateHttp.get("/buffet_space_event_details?id=" + id, config),
      cancelReservation: async (body, config) =>
        privateHttp.post("/cancel_event", body, config),
    },
    buffets: {
      getBuffet: async () => privateHttp.get("/buffet_space"),
      getPaginatedBuffets: async () => privateHttp.get("/home_consumer"),
      getBuffetEvent: async () => privateHttp.get("/filter_consumer"),
      getDisplayedFilters: async () => privateHttp.get("/home_filter"),
      getAdditionalFilters: async () =>
        privateHttp.get("/filter_consumer_search"),
      getBuffetById: async (buffet_id) =>
        privateHttp.get("/buffet_space_details?id=" + buffet_id),
      writeOpinion: async (body, config) =>
        privateHttp.post("buffet_space_opinion", body, config),
      getServices: async () =>
        privateHttp.get("/buffet_service"),
      getSpecialMenus: async () =>
        privateHttp.get("/buffet_menu"),
      getOpenBarWorks: async () =>
        privateHttp.get("/buffet_open_bar"),
      getCapacity: async () =>
        privateHttp.get("/buffet_capacity"),
    },
    listSearch: {
      getBufffetSearch: async (body) =>
        publicHttp.get("/search_buffet?" + body),
      getAddressSearch: async (code, config) =>
        privateHttp.get("search_address_code?code=" + code, config),
    },
    clientArea: {
      dataAccess: async (body, config) =>
        publicHttp.post("/request_info", body, config),
      accountDelete: async (body, config) =>
        privateHttp.post("delete_account", body, config),
    },
  },
};
