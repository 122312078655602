import { Button } from "@material-ui/core";
import { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { FaCoins } from "react-icons/fa";
import { MdGroup } from "react-icons/md";
import { useAuth } from "../../hooks/useAuth";

import style from "./style.module.scss";

function SearchItem({
  image,
  district,
  city,
  name,
  state,
  description,
  packageValue,
  capacity,
  score,
  onClick,
}) {
  const { isLoggedIn } = useAuth();
  const [isFavorite, setIsFavorite] = useState(false);

  return (
    <div className={style.container}>
      <div
        onClick={onClick && onClick}
        className={style.image}
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <div className={style.content}>
        <div className={style.item}>
          <div className={style.title}>
            <p onClick={onClick && onClick}>{name}</p>
          </div>
          <div className={style.info}>
            <AiFillStar style={{ color: "#FFE600" }} />
            <span>{score}</span>
            <span>
              {district}, {city} {state && `- ${state}`}
            </span>
          </div>
          <div>
            <div className={style.lastInfo}>
              <div className={style.infoPiece}>
                <FaCoins style={{ color: "#2C2C2C" }} />
                <span>
                  A partir de{" "}
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(packageValue)}
                </span>
              </div>
              <div className={style.infoPiece}>
                <MdGroup size="21" style={{ color: "#2C2C2C" }} />
                <span>
                  {capacity.min === 500
                    ? `${capacity.min}+`
                    : `${capacity.min} à ${capacity.max}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: "#00C2FF",
            color: "#fff",
            padding: "10px 30px",
            maxWidth: "16em",
            fontWeight: 900,
            borderRadius: 20,
            margin: "15px auto",
          }}
          onClick={onClick && onClick}
        >
          PEDIR ORÇAMENTO
        </Button>
      </div>
    </div>
  );
}

export default SearchItem;
