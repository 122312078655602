import { useContext } from "react";
import { ReservationContext } from "../contexts/reservationContext";

export function useReservations () {
  const {
    currentReservation,
    getReservation,
    setCurrentReservation,
    createReservation,
    getAllUserReservations,
    reservations,
    newReservation,
    setNewReservation,
  } = useContext(ReservationContext);

  return {
    currentReservation,
    getReservation,
    setCurrentReservation,
    createReservation,
    getAllUserReservations,
    reservations,
    newReservation,
    setNewReservation,
  };
}
