import { useHistory } from "react-router-dom";

import RegisterForm from "../../components/RegisterForm";

import style from "./style.module.scss";
import sideImg from "../../assets/images/register-image.png"
import logo from "../../assets/images/logo.png";

function Register() {
  const history = useHistory();

  return (
    <div className={style.container}>
      <aside
        className={style.sideImg}
        style={{ backgroundImage: `url(${sideImg})` }}
      />
      <div className={style.registerContainer}>
        <div className={style.logo}>
          <img
            onClick={() => {
              history.push("/");
            }}
            src={logo}
            alt='Dream Buffet'
          />
        </div>
        <RegisterForm />
      </div>
    </div>
  );
}

export default Register;
