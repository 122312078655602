import { createContext, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useHttp } from "../hooks/useHttp";
import { useToast } from "../hooks/useToast";

const ReservationContext = createContext({});

function ReservationProvider({ children }) {
  const { request } = useHttp()
  const { notify } = useToast()
  const { user } = useAuth()

  const [newReservation, setNewReservation] = useState({
    additionalServices: {
      additional_services_value: 0,
      items: []
    },
    additionalProviders: {
      additional_providers_value: 0,
      items: []
    }
  })
  const [currentReservation, setCurrentReservation] = useState({})
  const [reservations, setReservations] = useState([])

  async function getReservation (id) {
    const { data } = await request(`/buffet_space_event_details?id=${id}`, true, "GET");

    setCurrentReservation({
      buffet: {
        name: data?.data?.buffet_space.name,
        place: data?.data?.buffet_space.address.street,
        district: data?.data?.buffet_space.address.district,
        id: data?.data?.buffet._id,
      },
      buffet_space: data?.data?.buffet_space._id,
      event_id: data?.data?._id,
      status: data?.data?.status,
      last_status_updates: [data?.data?.status.date],
      date: data?.data?.date,
      refund: data?.data?.buffet_space?.refund?.enable ? data?.data?.buffet_space?.refund?.value : 0,
      customer: {
        name: data?.data?.people.name,
        code: data?.data?.people.code,
        birth: data?.data?.people.date,
      },
      payment_info: {
        method: "credit-card",
        card_type:  data?.data?.card?.brand || "unknown",
        last_digit: data?.data?.card?.last_digit || "****"
      },
      address: {
        code: data?.data?.buffet_space.address.code,
        place: data?.data?.buffet_space.address.street,
        district: data?.data?.buffet_space.address.district,
        number: data?.data?.buffet_space.address.number,
        complement: data?.data?.buffet_space.address.complement,
        city: data?.data?.buffet_space.address.city,
        state: data?.data?.buffet_space.address.state,
        location: {
          lat: data?.data?.buffet_space.address.location.coordinates[0],
          lng: data?.data?.buffet_space.address.location.coordinates[1],
        }
      },
      event: {
        invited_people: data?.data?.capacity?.max,
        date: data?.data?.event_date,
        period: data?.data?.event_hour,
        package_title: data?.data?.services[0].name,
      }
    })
  }

  async function createReservation (body) {
    try {
      const { data } = await request("/buffet_space_event", true, "POST", body);

      notify(`Parabéns, ${user.name}!`, "Reserva criada com sucesso", "success")

      return {
        status: true,
      }
    } catch (e) {
      notify(`Ops, ${user.name}!`, "Houve um erro ao criar a reserva", "error")

      return {
        status: false,
      }
    }
  }

  async function getAllUserReservations (body) {
    const { data } = await request("/buffet_space_event", true, "GET", body);

    setReservations(data?.data?.map((i) => ({
      id: i._id,
      requestID: i.id,
      buffet: i.buffet_space.name,
      date: i.event_date,
      hour: i.event_hour,
      paymentMethod: {
        card_type: i?.card?.brand || "unknown",
        method: "credit-card",
        last_digit: i.card.last_digit || '****'
      },
    })));
  }

  return (
    <ReservationContext.Provider
      value={{
        currentReservation,
        setCurrentReservation,
        getReservation,
        createReservation,
        getAllUserReservations,
        newReservation,
        setNewReservation,
        reservations
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
}

export { ReservationContext, ReservationProvider };
