import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/useToast";
import { Button } from "../../components/Button";

import emailInboxImage from "../../assets/images/vectors/emailInboxOpened.svg";
import style from './style.module.scss'

export default function RegisterEmailValidation () {
  const history = useHistory()
  const { notify } = useToast()

  const email = localStorage.getItem('register/email')

  useEffect(() => {
    if (!email) {
      history.push('/signup')
    }
  }, [email, history])

  const emailProvider = email?.split('@')[1]

  return (
    <div className={style.container}>
      <img src={emailInboxImage} alt="Email inbox" />
      <h1 className={style.title}>Verifique sua caixa de e-mail</h1>
      <p className={style.description}>
        Nós acabamos de te enviar um e-mail de confirmação, por favor, clique no link para concluir a criação da sua conta. Caso não encontre o e-mail, verifique sua caixa de spam.
      </p>
      <Button
        text='Verificar E-mail'
        customStyles={{
          fontWeight: 700,
          fontSize: '1.2rem',
          backgroundColor: '#00C2FF',
          borderRadius: '20px',
          boxShadow: 'none',
          color: '#fff',
          padding: '5px 40px',
          marginTop: '20px',
          marginBottom: '10px',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: '#eaeaea',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'
          }
        }}
        onClickFunction={() => {
          if (emailProvider === 'gmail.com') {
            window.open('https://mail.google.com')
          } else if (emailProvider === 'hotmail.com' || emailProvider === 'outlook.com') {
            window.open('https://outlook.live.com')
          } else if (emailProvider === 'yahoo.com') {
            window.open('https://mail.yahoo.com')
          } else {
            if (window.confirm('Não foi possível abrir sua caixa de e-mail automaticamente, deseja abrir manualmente?')) {
              if (!emailProvider) {
                notify('Falha ao ler e-mail!', 'A informação do seu e-mail está incorreta, por favor, tente realizar o cadastro novamente.', 'error')
              } else {
                window.open(`https://mail.${emailProvider}`)
              }
            }
          }
        }}
      />
      <Button
        text='Voltar para o início'
        customStyles={{
          fontWeight: 700,
          fontSize: '1.2rem',
          backgroundColor: '#eaeaea',
          borderRadius: '20px',
          boxShadow: 'none',
          color: '#00C2FF',
          padding: '5px 40px',
          marginTop: '20px',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: '#eaeaea',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'
          }
        }}
        onClickFunction={() => {
          history.push('/')
        }}
      />
    </div>
  )
}
