import { useEffect, useState } from "react";
import { useHttp } from "../../hooks/useHttp";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { SearchWithFiltersBar } from "../../components/SearchWithFiltersBar";
import { CarouselBackground } from "../../components/CarouselBackground";
import Navbar from "../../components/Navbar";
import HorizontalListView from "../../components/HorizontalListView";
import { BuffetCard } from "../../components/BuffetCard";
import LocationBanner from "../../components/LocationBanner";
import Footer from "../../components/Footer";
import { EventCard } from "../../components/EventCard";
import UserExperienceCard from "../../components/UserExperienceCard";

import style from "./style.module.scss";
import kidsPlayingImg from '../../assets/images/kidsPlaying.png'
import birthdayFrame from "../../assets/images/birthdayFrame.png";
import christmasFrame from "../../assets/images/christmasFrame.png";
import weddingFrame from "../../assets/images/weddingFrame.png";

function Home() {
  const { request } = useHttp();
  const history = useHistory();

  const isMobile = useMediaQuery({ maxWidth: 930 });
  const isNecessaryJustOne = useMediaQuery({ maxWidth: 650 });

  const [buffets, setBuffets] = useState([]);
  const [userExperiences, setUserExperiences] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/home_consumer", false, "GET");

      setBuffets(
        data.data.buffets.map((buffet) => {
          return {
            name: buffet.name,
            type: buffet.type.name,
            city: buffet.address.city,
            state: buffet.address.state,
            img: buffet.thumbnail_picture,
            pkgValue: buffet.package_start_value,
            capacityMin: buffet.capacity.min,
            capacityMax: buffet.capacity.max,
            score: buffet.opinion_score,
            avaliations: buffet.opinion_count,
            id: buffet._id,
          };
        })
      );

      setUserExperiences(
        data.data.comments.map((comment) => {
          return {
            id: comment._id,
            name: comment.people.name,
            message: comment.message,
            time: comment.date,
            img: comment.people.picture,
          };
        })
      );
    })();
  }, []);

  return (
    <div className={style.container}>
      <Navbar />
      <CarouselBackground
        images={[
          kidsPlayingImg
        ]}
      >
        {!isMobile && (
          <>
            <h1 className={style.titleAboveBg}>Encontre tudo o que precisa para sua festa ou evento.</h1>
            <p className={style.instructionAboveBg}>Preencha os campos abaixo e encontre diversos buffets de acordo com suas necessidades!</p>
            <SearchWithFiltersBar />
          </>
        )}
      </CarouselBackground>
      {isMobile && (
        <section className={style.section}>
          <h1 className={style.titleAboveBg}>Encontre tudo o que precisa para sua festa ou evento.</h1>
          <p className={style.instructionAboveBg}>Preencha os campos abaixo e encontre diversos buffets de acordo com suas necessidades!</p>
          <SearchWithFiltersBar />
        </section>
      )}
      <section className={style.section}>
        <h2 className={style.title}>Buffets em destaque</h2>
        <HorizontalListView maxLine={isNecessaryJustOne ? 1 : isMobile ? 2 : 3}>
          {buffets.map((buffet) => (
            <BuffetCard
              key={buffet.id}
              onClick={() => history.push(`/buffet/${buffet.id}`)}
              companyName={buffet.name}
              companyType={buffet.type}
              companyCity={buffet.city}
              companyState={buffet.state}
              companyPhoto={buffet.img}
              companyPackageValue={buffet.pkgValue}
              companyCapacityMin={buffet.capacityMin}
              companyCapacityMax={buffet.capacityMax}
              companyScore={buffet.score}
              companyTotalOpinions={buffet.avaliations}
            />
          ))}
        </HorizontalListView>
      </section>
      <section className={style.fullWidth}>
        <LocationBanner />
      </section>
      <section className={`${style.sectionFull} ${style.eventCards}`}>
        <h2 className={style.title}>Eventos</h2>
        <div className={style.eventCards__container}>
          <EventCard
            img={birthdayFrame}
            title="Aniversário"
          />
          <EventCard
            img={weddingFrame}
            title="Casamento"
          />
          <EventCard
            img={christmasFrame}
            title="Outros"
          />
        </div>
      </section>
      <section
        className={`${style.sectionFull} ${style.sectionUserExperience}`}
      >
        <h2 className={style.title}>Depoimentos</h2>
        <div className={style.cards}>
          {userExperiences?.map((userXP) => (
            <UserExperienceCard
              key={userXP.id}
              name={userXP.name}
              img={userXP.img}
              message={userXP.message}
              formattedDate={new Date(userXP.time).toLocaleDateString("pt-BR", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
