import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";
import { useToast } from "../../hooks/useToast";
import { Button } from "../../components/Button";

import verifyCheckImage from "../../assets/images/verifiedCheck.png";
import style from "./style.module.scss";

export default function EmailValidationComplete () {
  const history = useHistory()
  const { request } = useHttp()
  const { notify } = useToast()

  const token = useRef(null)
  
  const verifyToken = async (token) => {
    try {
      const { data: response } = await request('/confirm_account', false, 'POST', { token: token })

      if (!response.data.status) {
        notify('Falha ao verificar token', 'Erro na verificação do token, token inválido!', 'error')

        localStorage.removeItem('register/email')

        return history.push('/not-found')
      } else {
        notify('Conta verificada com sucesso!', 'Sua conta foi verificada com sucesso!', 'success')
      }
    } catch (error) {
      notify('Falha ao verificar token', error.message, 'error')
    }
  }

  useEffect(() => {
    if (token.current) return

    token.current = window.location.pathname.slice(1).split('/')[2]

    window.history.replaceState({}, document.title, '/signup/email-validation-complete')

    if (!token.current) {
      return history.push('/not-found')
    } else {
      verifyToken(token.current)
    }
  }, [])

  return (
    <div className={style.container}>
      <img src={verifyCheckImage} alt="Verificado" />
      <h1 className={style.title}>E-mail confirmado</h1>
      <p className={style.description}>
        Parabéns! Seu e-mail foi confirmado com sucesso e agora você pode aproveitar todos os recursos da&nbsp;<a href='/' className={style.link}>Dream Buffets</a>!
      </p>
      <Button
        text='Ir para o início'
        customStyles={{
          fontWeight: 700,
          fontSize: '1.2rem',
          backgroundColor: '#00C2FF',
          borderRadius: '20px',
          boxShadow: 'none',
          color: '#fff',
          padding: '5px 40px',
          marginTop: '20px',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: '#eaeaea',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'
          }
        }}
        onClickFunction={() => {
          history.push('/signin')
        }}
      />
    </div>
  )
}
