import { useToast } from '../../hooks/useToast';
import { Modal } from '../Modal';
import { BsFacebook, BsLink45Deg } from 'react-icons/bs';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';

import style from './style.module.scss';

export function ShareModal ({ handleClose }) {
  const { notify } = useToast();

  return (
    <Modal
      darkBackground
      modalPosition="centered"
      modalSize="medium"
      closeIconPosition='right'
      handleClose={handleClose}
    >
     <div className={style.header}>
        <strong>Compartilhar</strong>
      </div>
      <div className={style.content}>
        <button
          type="button"
          onClick={() => {
            const fbShareApi = "https://www.facebook.com/sharer/sharer.php?u=";

            window.open(fbShareApi + window.location.href, '_blank');
          }}
        >
          <BsFacebook size="1.8rem" color="#4267B2" />
          <span>Compartilhar no Facebook</span>
        </button>
        <button
          type="button"
          onClick={() => {
            const twShareApi = "https://twitter.com/intent/tweet?text=";

            window.open(twShareApi + window.location.href, '_blank');
          }}
        >
          <AiFillTwitterCircle size="2.2rem" color="#1DA1F2" />
          <span>Compartilhar no Twitter</span>
        </button>
        <button
          type="button"
          onClick={() => {
            const wpShareApi = "https://wa.me/?text="

            window.open(wpShareApi + window.location.href, '_blank');
          }}
        >
          <IoLogoWhatsapp size="2.1rem" color="#128C7E" />
          <span>Compartilhar no Whatsapp</span>
        </button>
        <button
          type="button"
          onClick={() => {
            try {
              navigator.clipboard.writeText(window.location.href);

              notify('Sucesso!', 'Link copiado para a área de transferência', 'success')
            } catch (err) {
              notify('Erro!', 'Não foi possível copiar o link', 'error')
              console.log(err)
            }
          }}
        >
          <BsLink45Deg size="1.8rem" color="#a3a3a3" />
          <span>Copiar link</span>
        </button>
      </div>
    </Modal>
  );
}
