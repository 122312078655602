import { useAuth } from "../../hooks/useAuth";

import { RiPencilFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BsFillBagCheckFill } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";

import style from "./style.module.scss";

export function Sidebar() {
  const history = useHistory();
  const location = useLocation();

  const { handleSignOut } = useAuth();

  return (
    <div className={`${style.container} ${style.lessSpaceInLeft}`}>
      <div className={style.withRightBorder}>
        <button
          type="button"
          disabled={location.pathname === "/edit-profile"}
          onClick={() => history.push("/edit-profile")}
          className={`${style.navBtn} ${
            location.pathname === "/edit-profile" && style.selected
          }`}
        >
          <RiPencilFill className={style.navIcon} size="22" />
          <span>Editar perfil</span>
        </button>
        <button
          type="button"
          disabled={location.pathname === "/my-requests"}
          onClick={() => history.push("/my-requests")}
          className={`${style.navBtn} ${
            location.pathname === "/my-requests" && style.selected
          }`}
        >
          <BsFillBagCheckFill className={style.navIcon} size="20" />
          <span>Meus pedidos</span>
        </button>

        <button
          type="button"
          onClick={() => {
            handleSignOut();

            history.push("/");
            window.location.reload();
          }}
          className={style.logoutBtn}
        >
          <BiLogOut className={style.logoutIcon} size="22" />
          <span>Desconectar</span>
        </button>
      </div>
    </div>
  );
}
