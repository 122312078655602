import { useSearch } from '../../hooks/useSearch';

import style from './style.module.scss';
import weddingLarge from '../../assets/images/weddingLarge.png';
import birthdayFrame from '../../assets/images/birthdayFrame.png';
import christmasFrame from '../../assets/images/christmasFrame.png';

export function SearchBackground ({ children }) {
  const { search } = useSearch()

  const images = [
    weddingLarge,
    birthdayFrame,
    christmasFrame
  ]

  return (
    <div className={style.searchBackground}>
      <div
        className={style.searchBackground__image}
        style={{
          backgroundImage: `url(${images[
            search.eventType === 'casamento' ? 0 : search.eventType === 'aniversario' ? 1 : 2
          ]})`,
        }}
      />
      <div className={style.searchBackground__content}>{children}</div>
    </div>
  );
}
