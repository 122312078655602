import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useReservations } from "../../hooks/useReservations";
import { useToast } from "../../hooks/useToast";
import { useHttp } from "../../hooks/useHttp";

import { SocketContext } from "../../contexts/socket";
import { EventStatusBar } from "../../components/EventStatusBar";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";
import ReactInputMask from "react-input-mask";
import Navbar from "../../components/Navbar";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import ReactQuill from "react-quill";
import { GoPrimitiveDot } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { AiOutlineStar } from "react-icons/ai";
import { BsCheckCircle, BsHeadset } from "react-icons/bs";

import style from "./style.module.scss";
import "../../utils/scss/quill.scss";
import "react-quill/dist/quill.snow.css";

import visa from "../../assets/images/vectors/visa.png";
import mastercard from "../../assets/images/vectors/mastercard.png";
import amex from "../../assets/images/vectors/amex.png";
import elo from "../../assets/images/vectors/elo.png";
import hipercard from "../../assets/images/vectors/hipercard.png";
import dinersclub from "../../assets/images/vectors/dinersclub.png";
import calendar from "../../assets/images/vectors/calendar.png";
import { GrStatusUnknown } from "react-icons/gr";
import { Map } from "../../components/Map";

function RequestDetails() {
  const history = useHistory();
  const { id } = useParams();
  const { notify } = useToast();
  const { request } = useHttp();
  const { connected, handleEvents } = useContext(SocketContext);
  const { getReservation, currentReservation, setCurrentReservation } =
    useReservations();
  const isMobile = !useMediaQuery({ maxWidth: 930 });
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [supportMessage, setSupportMessage] = useState({
    message: "",
    subject: "",
  });
  const [cancellationTerms, setCancellationTerms] = useState("");
  const [showModal, setShowModal] = useState({
    cancellationModal: false,
    cancellationReasonModal: false,
    opinionModal: false,
    showSupportModal: false,
  });
  const [userOpinion, setUserOpinion] = useState({
    rating: 0,
    opinion: "",
  });

  const cardImages = [
    {
      name: "visa",
      image: visa,
    },
    {
      name: "mastercard",
      image: mastercard,
    },
    {
      name: "amex",
      image: amex,
    },
    {
      name: "elo",
      image: elo,
    },
    {
      name: "hipercard",
      image: hipercard,
    },
    {
      name: "dinersclub",
      image: dinersclub,
    },
  ];

  function dateDiffFunction(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  async function handleCancelReservation() {
    const body = {
      order: id,
    };

    try {
      const { data } = await request("/cancel_event", true, "POST", body);

      if (data.status) {
        notify(
          "Feito!",
          `Reserva de ${currentReservation?.customer?.name} cancelada com sucesso!`,
          "success"
        );

        setShowModal((old) => ({
          ...old,
          showCancelModal: false,
        }));

        history.push("/my-requests");
      } else {
        notify("Erro!", "Reserva não pôde ser cancelada", "error");
      }
    } catch (e) {
      notify("Erro!", e.message, "error");
    }
  }

  async function handleSendSupportMessage() {
    const { data } = await request("/request_support", true, "POST", {
      message: supportMessage.message,
      subject: supportMessage.subject,
    });

    if (data.status) {
      notify("Sucesso!", "Sua mensagem foi enviada com sucesso!", "success");
      setShowModal((old) => ({
        ...old,
        showSupportModal: false,
      }));
      setSupportMessage({
        message: "",
        subject: "",
      });
    } else {
      notify("Erro!", "Não foi possível enviar sua mensagem", "error");
    }
  }

  useEffect(() => {
    (async () => {
      const { data } = await request(
        "/terms?type=cancellation_policy",
        false,
        "GET"
      );

      setCancellationTerms(data?.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getReservation(id);
    })();
  }, []);

  const timePeriod = () => {
    switch (currentReservation?.event?.period) {
      case "morning":
        return "Manhã";
      case "afternoon":
        return "Tarde";
      case "night":
        return "Noite";
      default:
        break;
    }
  };

  function findLastIndex(array, searchValue, searchKey, subKey) {
    let index = array
      .slice()
      .reverse()
      .findIndex((x) => {
        let testValue = subKey ? x[searchKey][subKey] : x[searchKey];
        return testValue === searchValue;
      });
    let count = array.length - 1;

    let finalIndex = index >= 0 ? count - index : index;

    return finalIndex;
  }

  const checkValues = (array, codes) => {
    let boolean = false;

    if (!array) return boolean;

    codes.forEach((code) => {
      if (array[findLastIndex(array, code, "details", "code")]) boolean = true;
    });

    if (new Date(currentReservation.event.date) < new Date()) boolean = true;

    return boolean;
  };

  async function handleGiveOpinion() {
    if (!currentReservation) return;

    const body = {
      buffet: currentReservation.buffet.id,
      buffet_space: currentReservation.buffet_space,
      event: id,
      message: userOpinion.opinion,
      score: userOpinion.rating,
    };

    try {
      const { data } = await request(
        "/buffet_space_opinion",
        true,
        "POST",
        body
      );

      if (data?.new) {
        notify("Feito!", "Opinião enviada com sucesso!", "success");

        setUserOpinion({
          rating: 0,
          opinion: "",
        });

        setShowModal((old) => ({
          ...old,
          showOpinionModal: false,
        }));
      } else {
        notify("Erro!", "Opinião não pôde ser enviada", "error");
      }
    } catch (e) {
      notify("Erro!", e.message, "error");
    }
  }

  useEffect(() => {
    if (!connected) return;

    handleEvents("update_order", (data) => {
      setCurrentReservation({
        buffet: {
          name: data.info.buffet.name,
          place: data.info.buffet.address.street,
          district: data.info.buffet.address.district,
          id: data.info.buffet._id,
        },
        buffet_space: data.info.buffet_space._id,
        event_id: data.info._id,
        status: data.info.status,
        last_status_updates: [data.info.status.date],
        date: data.info.date,
        customer: {
          name: data.info.people.name,
          code: data.info.people.code,
          birth: data.info.people.date,
        },
        payment_info: {
          method: "credit-card",
          card_type: data.info.card.brand || "unknown",
          last_digit: data.info.card.last_digit || "****",
        },
        address: {
          code: data.info.buffet_space.address.code,
          place: data.info.buffet_space.address.street,
          district: data.info.buffet_space.address.district,
          number: data.info.buffet_space.address.number,
          complement: data.info.buffet_space.address.complement,
          city: data.info.buffet_space.address.city,
          state: data.info.buffet_space.address.state,
          location: {
            lat: data.info.buffet_space.address.location.coordinates[0],
            lng: data.info.buffet_space.address.location.coordinates[1],
          }
        },
        event: {
          invited_people: data.info.capacity.max,
          date: data.info.event_date,
          period: data.info.event_hour,
          package_title: data.info.services[0].name,
        },
      });
    });
  }, [connected]);

  const validCancellationUntil = () =>
    new Date(currentReservation?.event?.date).setDate(
      new Date(currentReservation?.event?.date).getDate() - 15
    );

  return (
    <div className={style.container}>
      <Navbar />

      <div className={style.title}>
        <h1>{currentReservation?.buffet?.name}</h1>
        <p>
          {new Date(currentReservation?.event?.date).toLocaleDateString(
            "pt-BR",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "America/Sao_Paulo",
            }
          )}{" "}
          - {currentReservation?.event?.period && timePeriod()}
        </p>
      </div>
      <EventStatusBar
        statusValues={currentReservation?.status?.map((status) => ({
          date: status.date,
          code: status.details.code,
          value: status.details.name,
        }))}
        standardStatus={{
          firstBar: [
            {
              code: null,
              value: "aguardando confirmação",
            },
            {
              code: null,
              value: "evento agendado",
            },
            {
              code: null,
              value: "evento cancelado",
            },
          ],
          secondBar: [
            {
              code: 999,
              value: "aguardando pagamento",
            },
            {
              code: 10,
              value: "pagamento confirmado",
            },
            {
              code: 1000,
              value: "falha no pagamento",
            },
          ],
          thirdBar: [
            {
              code: null,
              value: "em breve",
            },
            {
              code: 1,
              value: "evento finalizado",
            },
            {
              code: 0,
              value: "evento cancelado",
            }
          ]
        }}
        reservationDay={currentReservation?.date}
        eventDay={currentReservation?.event?.date}
      />

      <div className={style.content}>
        <div className={style.leftContent}>
          <label htmlFor="consumer-info">Informações do Consumidor</label>
          <input
            className={style.input}
            type="text"
            name="consumer-info"
            id="consumer-info-name"
            disabled
            value={currentReservation?.customer?.name || ""}
          />
          <ReactInputMask
            className={style.input}
            type="text"
            name="consumer-info"
            id="consumer-info-code"
            disabled
            value={currentReservation?.customer?.code || ""}
            mask="999.999.999-99"
          />
          <input
            className={style.input}
            type="text"
            name="consumer-info"
            id="consumer-info-birth"
            disabled
            value={
              new Date(currentReservation?.customer?.birth).toLocaleDateString(
                "pt-BR",
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  timeZone: "America/Sao_Paulo",
                }
              ) || ""
            }
          />

          <label htmlFor="payment-info">Informações do Pagamento</label>
          {currentReservation?.payment_info?.method === "credit-card" && (
            <div className={style.paymentMethodInfo}>
              <div
                className={`${style.creditCard} ${
                  currentReservation?.payment_info?.card_type.toLowerCase() ===
                    "visa" ||
                  currentReservation?.payment_info?.card_type.toLowerCase() ===
                    "diners_club" ||
                  currentReservation?.payment_info?.card_type.toLowerCase() ===
                    "amex"
                    ? style.lightBg
                    : ""
                }`}
              >
                {currentReservation?.payment_info?.card_type === "unknown" ? (
                  <GrStatusUnknown size="1rem" />
                ) : (
                  <img
                    src={
                      cardImages.find(
                        (card) =>
                          card.name ===
                          currentReservation?.payment_info?.card_type.toLowerCase()
                      )?.image
                    }
                    alt={currentReservation?.payment_info?.card_type}
                  />
                )}
              </div>
              <div className={style.cardHiddenNumbers}>
                <GoPrimitiveDot size={18} />
                <GoPrimitiveDot size={18} />
                <GoPrimitiveDot size={18} />
                <GoPrimitiveDot size={18} />
              </div>
              {currentReservation.payment_info.last_digit}
            </div>
          )}
          <ReactInputMask
            className={style.input}
            type="text"
            name="payment-info"
            id="payment-info-address-code"
            disabled
            value={currentReservation?.address?.code || ""}
            mask="99999-999"
          />
          <input
            className={style.input}
            type="text"
            name="payment-info"
            id="payment-info-address-place"
            disabled
            value={currentReservation?.address?.place || ""}
          />
          {currentReservation?.address?.complement && (
            <input
              className={style.input}
              type="text"
              name="payment-info"
              id="payment-info-address-complement"
              disabled
              value={currentReservation.address.complement}
            />
          )}

          <label htmlFor="event-info">Informações do Evento</label>
          <p>
            <strong
              style={{
                textTransform: "uppercase",
              }}
            >
              {currentReservation?.buffet?.name}
            </strong>{" "}
            <div className={style.dot} /> {currentReservation?.address?.place} -{" "}
            {currentReservation?.address?.district}
          </p>
          <input
            className={style.input}
            type="text"
            name="event-info"
            id="event-info-invited-people"
            disabled
            value={
              `${currentReservation?.event?.invited_people} convidados` || ""
            }
          />
          <input
            className={style.input}
            type="text"
            name="event-info"
            id="event-info-date"
            disabled
            value={
              `${new Date(currentReservation?.event?.date).toLocaleDateString(
                "pt-BR",
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  timeZone: "America/Sao_Paulo",
                }
              )} - ${timePeriod()}` || ""
            }
          />
          <input
            className={style.input}
            type="text"
            name="event-info"
            id="event-info-package"
            disabled
            value={currentReservation?.event?.package_title || ""}
          />
        </div>
        {!isMobile ? (
          ""
        ) : (
          <div className={style.rightContent}>
            <span className={style.cancelPolicytitle}>
              Política de cancelamento
            </span>
            <p className={style.text}>
              <strong>
                Cancelamento gratuito antes das 18:00 do dia{" "}
                {new Date(currentReservation?.event?.date).getUTCDate()} de{" "}
                {
                  [
                    "janeiro",
                    "fevereiro",
                    "março",
                    "abril",
                    "maio",
                    "junho",
                    "julho",
                    "agosto",
                    "setembro",
                    "outubro",
                    "novembro",
                    "dezembro",
                  ][new Date(currentReservation?.event?.date).getMonth()]
                }
                .
              </strong>{" "}
              Depois disso, cancele até 06:00h do dia{" "}
              {`${new Date(validCancellationUntil()).getDate()} ${
                [
                  "jan.",
                  "fev.",
                  "mar.",
                  "abr.",
                  "mai.",
                  "jun.",
                  "jul.",
                  "ago.",
                  "set.",
                  "out.",
                  "nov.",
                  "dez.",
                ][new Date(validCancellationUntil()).getMonth()]
              }`}{" "}
              e receba um reembolso de {currentReservation?.refund}%, menos os fornecedores adicionais e
              taxa de serviço.
            </p>
            <p className={style.text}>
              Nossa Política de Causas de Força Maior não cobre interrupções de
              eventos causadas pela COVID-19.{" "}
              <a href="/cancellation-policy">Saiba mais.</a>
            </p>
            <hr />
            <div className={style.bottomContent}>
              <div className={style.image}>
                <img src={calendar} />
              </div>
              <div>
                <p className={style.text}>
                  <strong>
                    Sua reserva só será confirmada depois que o<br />
                    buffet aceitar seu pedido (dentro de 24 horas).
                  </strong>
                </p>
                <p className={style.text}>A cobrança só é feita depois.</p>
              </div>
            </div>
            <hr />
            <div className={style.mapContainer}>
              <h3>Mapa</h3>
              <h4>{currentReservation?.buffet?.name}</h4>
              <p>{
                currentReservation?.address?.place + ' - '
                + currentReservation?.address?.district + ' - '
                + currentReservation?.address?.city + ', '
                + currentReservation?.address?.state
              }</p>
              <Map 
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: "100%" }} />}
                containerElement={
                  <div
                    style={{
                      height: "20rem",
                      width: "100%",
                      borderRadius: "14px",
                      overflow: "hidden",
                      marginTop: "1.5rem",
                    }}
                  />
                }
                mapElement={<div style={{ height: "100%" }} />}
                location={{
                  lat: currentReservation?.address?.location?.lat,
                  lng: currentReservation?.address?.location?.lng,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className={style.actions}>
        <div className={style.leftActions}>
          <button
            className={style.cancellationBtn}
            disabled={checkValues(currentReservation?.status, [1000, 0])}
            onClick={() =>
              setShowModal((old) => ({
                ...old,
                showCancelModal: true,
              }))
            }
          >
            <IoClose size={"1.4rem"} />
            Cancelar evento
          </button>
          {isMobile && (
            <button
              disabled={!checkValues(currentReservation?.status, [1, 1000, 0])}
              onClick={() =>
                setShowModal((old) => ({
                  ...old,
                  showOpinionModal: true,
                }))
              }
            >
              <AiOutlineStar size={"1.4rem"} />
              Avaliar buffet
            </button>
          )}
        </div>
        <div className={style.rightActions}>
          <button
            onClick={() =>
              setShowModal((old) => ({ ...old, showSupportModal: true }))
            }
          >
            <BsHeadset size={"1.4rem"} />
            Falar com o suporte
          </button>
        </div>
      </div>
      {/* Modals */}
      {showModal.showCancelModal && (
        <Modal
          darkBackground
          hiddenOverflow
          modalPosition="centered"
          modalSize="big"
          closeIconPosition="right"
          handleClose={() =>
            setShowModal((old) => ({
              ...old,
              showCancelModal: false,
            }))
          }
        >
          <div className={style.modalHeader}>
            <strong>POLÍTICA DE CANCELAMENTO</strong>
          </div>
          <div className={style.modalContent}>
            <div className={style.scrollView}>{cancellationTerms}</div>
          </div>
          <div className={style.modalActions}>
            <Button
              text="CANCELAR"
              type="button"
              className={`${style.modalBtn} ${style.modalCancellationBtn}`}
              onClickFunction={handleCancelReservation}
            />
          </div>
        </Modal>
      )}

      {showModal.cancellationReasonModal && (
        <Modal
          darkBackground
          modalPosition="centered"
          modalSize="big"
          closeIconPosition="right"
          handleClose={() =>
            setShowModal((old) => ({
              ...old,
              cancellationReasonModal: false,
            }))
          }
        >
          <div className={style.modalHeader}>
            <strong>EVENTO CANCELADO!</strong>
          </div>
          <div className={style.modalContent}>
            <BsCheckCircle color="#00c2ff" size="7em" />
            <p className={style.centerText}>
              Seu evento previsto para o dia{" "}
              <span>
                {new Date(currentReservation?.event?.date).toLocaleString(
                  "pt-BR",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    timeZone: "America/Sao_Paulo",
                  }
                )}
              </span>{" "}
              foi cancelado e já notificamos o responsável pelo buffet.
            </p>
          </div>
          <div className={style.modalActions}>
            <Button
              text="VOLTAR PARA O INÍCIO"
              onClickFunction={() => {
                setShowModal((old) => ({
                  ...old,
                  cancellationReasonModal: false,
                }));
                history.push("/");
              }}
              type="button"
              className={style.modalBtn}
            />
          </div>
        </Modal>
      )}

      {showModal.showOpinionModal && (
        <Modal
          darkBackground
          modalPosition="centered"
          modalSize="big"
          closeIconPosition="right"
          handleClose={() =>
            setShowModal((old) => ({
              ...old,
              showOpinionModal: false,
            }))
          }
        >
          <div className={style.modalHeader}>
            Como foi a sua experiência com&nbsp;
            <strong>{currentReservation.buffet.name}</strong>
          </div>
          <div className={style.modalContent}>
            <div className={style.rating}>
              <Stack spacing={1}>
                <Rating
                  name="half-rating"
                  precision={0.5}
                  onChange={(event, newValue) => {
                    setUserOpinion((old) => ({
                      ...old,
                      rating: newValue,
                    }));
                  }}
                  value={userOpinion.rating}
                />
              </Stack>
            </div>
            <div className={`${style.opinion} __react_quill_style_for_opinion`}>
              <ReactQuill
                theme="snow"
                content={userOpinion.opinion}
                onChange={(value) => {
                  setUserOpinion((old) => ({
                    ...old,
                    opinion: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className={style.modalActions}>
            <Button
              text="RESPONDER"
              type="button"
              onClickFunction={handleGiveOpinion}
              className={style.modalBtn}
            />
          </div>
        </Modal>
      )}

      {showModal.showSupportModal && (
        <Modal
          darkBackground
          modalPosition="centered"
          modalSize="big"
          closeIconPosition="right"
          handleClose={() =>
            setShowModal((old) => ({
              ...old,
              showSupportModal: false,
            }))
          }
        >
          <div className={style.modalHeader}>
            <h3>
              Deixe sua mensagem ao suporte da{" "}
              <strong className={style.blue}>Dream Buffets.</strong>
            </h3>
          </div>
          <div className={style.modalContent}>
            <div className={style.scrollView}>
              <p className={style.instructions}>
                Responderemos sua solicitação em até 1 dia útil, você poderá
                acompanhá-la através do seu e-mail cadastrado.
              </p>
              <input
                type="text"
                placeholder="Assunto"
                value={supportMessage.subject}
                onChange={(event) => {
                  setSupportMessage((old) => ({
                    ...old,
                    subject: event.target.value,
                  }));
                }}
              />
              <div
                className={`${style.opinion} __react_quill_style_for_opinion`}
              >
                <ReactQuill
                  theme="snow"
                  content={supportMessage.message}
                  onChange={(value) => {
                    setSupportMessage((old) => ({
                      ...old,
                      message: value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.modalActions}>
            <Button
              text="ENVIAR"
              type="button"
              disabled={!supportMessage.message || !supportMessage.subject}
              onClickFunction={handleSendSupportMessage}
              className={style.modalBtn}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default RequestDetails;
