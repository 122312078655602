import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Logo from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";
import { useToast } from "../../hooks/useToast";
import style from "../../styles/clientArea.module.scss";

function SubscriptionCancel() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const history = useHistory();
  const { request } = useHttp()
  const { notify } = useToast()

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data: response } = await request('/unsubscribe', true, 'POST', { name, email });

    if (response.status) notify('Solicitação feita com sucesso!', 'Verifique as informações no e-mail informado!', 'success')
  }

  return (
    <div
      style={{
        display: "flex",
        overflowX: "hidden",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "85%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: 200,
            cursor: "pointer",
          }}
          src={Logo}
          onClick={() => history.push("/")}
          alt="Dream Buffets logo"
        />
        <h1>Área do cliente</h1>
        <div className={style.navigationBar}>
          <span
            style={{
              borderRadius: "50px 0 0 50px",
              backgroundColor: "#fff",
              color: "#00c2ff",
            }}
            onClick={() => history.push("/client-area/data-access")}
          >
            PEDIDOS DE ACESSO A DADOS
          </span>
          <span
            style={{
              backgroundColor: "#fff",
              color: "#00c2ff",
            }}
            onClick={() => history.push("/client-area/data-forget")}
          >
            ESQUEÇA O PEDIDO DE DADOS
          </span>
          <span
            style={{
              backgroundColor: "#fff",
              color: "#00c2ff",
            }}
            onClick={() => history.push("/client-area/data-rectify")}
          >
            RETIFICAR SOLICITAÇÃO DE DADOS
          </span>
          <span
            style={{
              borderRadius: "0 50px 50px 0",
              backgroundColor: "#00c2ff",
              color: "#fff",
            }}
          >
            SOLICITAÇÃO DE CANCELAMENTO DE INSCRIÇÃO
          </span>
        </div>
      </div>
      <div className={style.bodyContainer}>
        <form
          style={{
            width: "65%",
            color: "#C5C5C5",
          }}
          onSubmit={handleSubmit}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              color: "#00c2FF",
              textAlign: "center",
            }}
          >
            <p>
              Por favor, reconsidere o cancelamento da assinatura, pois você
              pode perder atualizações e ofertas importantes de nossa parte. Se
              você realmente quiser cancelar a inscrição, preencha seu endereço
              de e-mail. Na caixa abaixo
            </p>
          </div>

          <div className={style.formContainer}>
            <TextField
              style={{
                height: 50,
              }}
              value={name}
              className={style.inputText}
              variant="standard"
              label="Nome"
              onChange={(e) => setName(e.target.value)}
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
            />
            <TextField
              style={{
                height: 50,
              }}
              value={email}
              className={style.inputText}
              variant="standard"
              label="E-mail"
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ style: { fontSize: 20 } }}
              InputLabelProps={{ style: { fontSize: 20 } }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: 75,
            }}
          >
            <Button
              style={{
                fontWeight: "bold",
                backgroundColor: "#00C2FF",
                borderRadius: "20px",
                boxShadow: "none",
                width: "130px",
                height: "35px",
              }}
              type="submit"
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SubscriptionCancel;
