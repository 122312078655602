import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/useToast";

import style from "./style.module.scss";

import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";

function LoginForm() {
  const buffetAdminPath = process.env.REACT_APP_PROVIDER_PATH;
  const { notify } = useToast();

  const { handleSignIn } = useAuth();

  const [login, setLogin] = useState({
    username: "",
    password: "",
  });

  const history = useHistory();

  const [seePass, setSeePass] = useState(false);

  async function handleLoginClient(event) {
    event.preventDefault();

    try {
      await handleSignIn({
        username: login.username,
        password: login.password,
        type: "people",
      });

      notify("Bem-vindo!", "Login realizado com sucesso!", "success");

      history.push("/");
    } catch (err) {
      notify(
        "Erro",
        "Não foi possível realizar o login, verifique o e-mail e senha usados, por favor!",
        "error"
      );
    }
  }

  return (
    <div>
      <form onSubmit={handleLoginClient} className={style.signInForm}>
        <h1>Entre em sua conta</h1>
        <p>
          Não possui uma conta?
          <span
            onClick={() => history.push("/signup")}
            style={{ color: "#00C2FF", cursor: "pointer" }}
          >
            Registrar-se
          </span>
        </p>
        <p
          style={{
            margin: "20px 0",
            fontWeight: "500",
            fontSize: "1.2rem",
          }}
        >
          Ou acesse com seu e-mail
        </p>
        <div className={style.input}>
          <TextField
            variant="standard"
            label="E-mail"
            style={{ width: "50%", marginBottom: 20 }}
            onChange={(e) => setLogin({ ...login, username: e.target.value })}
            value={login.username}
          />
        </div>
        <div className={style.input}>
          <TextField
            variant="standard"
            label="Senha"
            style={{ width: "50%", marginBottom: 20 }}
            type={seePass ? "text" : "password"}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
            value={login.password}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!seePass ? (
                    <IconButton
                      onClick={() => {
                        setSeePass(true);
                      }}
                    >
                      <RiEyeCloseFill />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSeePass(false);
                      }}
                    >
                      <RiEyeFill />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <p
          className={style.instruction}
          onClick={() => {
            history.push("/recover-password");
          }}
          style={{
            textAlign: "center",
            cursor: "pointer",
            color: "#00C2FF",
          }}
        >
          Esqueceu sua senha?
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button type="submit" className={style.registerBtn}>
            Entrar
          </Button>
        </div>
        <div style={{ margin: "10%" }}>
          <p
            style={{
              cursor: "pointer",
              marginBottom: 20,
            }}
          >
            Você é um profissional?
          </p>
          <a
            href={`${buffetAdminPath}/login`}
            style={{
              cursor: "pointer",
              color: "#00C2FF",
            }}
          >
            Acesso para empresas
          </a>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
