import Calendar from "react-calendar";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import "../../utils/scss/reactCalendar.scss";

export function CalendarComponent({
  showDoubleView,
  onClickDay,
  tileClassName,
  tileDisabled,
}) {
  return (
    <Calendar
      className="calendar-view-container"
      showDoubleView={showDoubleView ? true : false}
      nextLabel={<IoIosArrowForward size="18" />}
      prevLabel={<IoIosArrowBack size="18" />}
      minDetail="month"
      prev2Label={null}
      next2Label={null}
      tileDisabled={tileDisabled}
      onClickDay={onClickDay && onClickDay}
      tileClassName={tileClassName && tileClassName}
    />
  );
}
