import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Logo from "../../assets/images/logo.png";
import { Button } from "../../components/Button";
import { useHttp } from "../../hooks/useHttp";

import "./style.scss";

function UseTerms() {
  const { t } = useTranslation();
  const { request } = useHttp();
  const history = useHistory();

  const [terms, setTerms] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await request("/terms?type=terms_use", false, "GET");

      setTerms(data?.data);
    })();
  }, []);

  return (
    <div>
      <main className="use-terms-container">
        <img className="logo" src={Logo} onClick={() => history.push("/")} />

        <h1>{t("Termos de uso")}</h1>

        <div className="terms-container">{terms}</div>
      </main>
    </div>
  );
}

export default UseTerms;
