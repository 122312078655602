import "dotenv";
import { createContext, useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { useAuth } from "../hooks/useAuth";

const SocketContext = createContext({});

function SocketProvider({ children }) {
  const { handleSignOut } = useAuth();

  const apiURL = process.env.REACT_APP_API_URL;

  const socket = useRef(null);
  const [connected, setConnected] = useState(false);

  const handleEmitMessage = (action, info) => {
    let token = localStorage.getItem("@dream/token");

    if (!token) return;

    socket.current.emit("message", {
      action,
      token,
      info,
    });
  };

  const handleEvents = (action, fn) => {
    socket.current.on(`_message@${action}`, fn);
  };

  const connectWebsocket = () => {
    socket.current = socketIOClient(apiURL, {
      transports: ["websocket", "polling"],
    });

    socket.current.on("connect", () => {
      setConnected(true);

      let token = localStorage.getItem("@dream/token");

      if (!token) return;

      socket.current.emit("message", {
        action: "auth",
        info: {
          token: token,
        },
      });
    });

    socket.current.on("disconnect", async () => {
      console.log("Socket disconnected");
    });

    handleEvents("auth/signin", async (data) => {
      if (!data.status) {
        handleSignOut()
      } else {
        console.log(`[socket] authenticated as ${data.info.user.name}`)
      }
    });
  };

  useEffect(() => {
    connectWebsocket();

    return () => {
      socket.current.close();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        handleEmitMessage,
        handleEvents,
        connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
