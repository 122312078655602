import Logo from "../../assets/images/logo.png";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { AiOutlineMenu } from "react-icons/ai";
import { Navigation } from "../Navigation";

import style from "./style.module.scss";

function Navbar() {
  const history = useHistory();

  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: 930 });

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  if (isMobile)
    return (
      <nav className={`${style.navbar} ${style.navbarMobile}`}>
        <div
          onClick={() => history.push("/")}
          className={style.navbarLogo}
        >
          <img src={Logo} className={style.logo} />
        </div>
        {location.pathname != "/payment" && (
          <button className={style.drawerBtn} onClick={toggleDrawer(true)}>
            <AiOutlineMenu size="22" />
          </button>
        )}
        <Drawer
          anchor="top"
          className={style.drawer}
          open={isOpen}
          onClose={toggleDrawer(false)}
        >
          <Navigation />
        </Drawer>
      </nav>
    );

  return (
    <nav className={style.navbar}>
      <div onClick={() => history.push("/")} className={style.navbarLogo}>
        <img src={Logo} className={style.logo} />
      </div>
      <Navigation />
    </nav>
  );
}

export default Navbar;
