export function EventSuggestion({ value, number }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
      value={value}
    >
      <p style={{ marginRight: "8px" }}>{value}</p>
      {/*<span style={{ color: "#8c8c8c" }}>{number}</span>*/}
    </div>
  );
}
