import toast from 'react-hot-toast'
import { Toast } from '../components/Toast'
import { IoMdClose } from 'react-icons/io'
import dreamBuffetMiniLogo from '../assets/images/dreamBuffetMiniLogo.png'

import '../utils/scss/toasts.scss'

export function useToast () {
  const notify = (title, message, type) => toast((t) => (
    <div className={`toast-container ${type}`}>
      <button className='toast-close-btn' onClick={() => toast.dismiss(t.id)}>
        <IoMdClose size={16} />
      </button>
      <img className='toast-img-container' src={dreamBuffetMiniLogo} alt='Dream Buffets' />
      <div className="toast-body">
        <h3 className={
          type === 'error'&& 'toast-title-error'
          || type === 'success' && 'toast-title-success'
          || type === 'warning' && 'toast-title-warning'
          || 'toast-title-info'
        }>{
          title
        }</h3>
        <p>{message}</p>
      </div>
    </div>
  ))

  return {
    notify,
    Toast
  }
}
