import style from "./style.module.scss";

export function EventCard ({ img, title, onClick }) {
  return (
    <div className={style.eventCard} onClick={onClick}>
      <div
        className={style.eventCard__image}
        style={{
          backgroundImage: `url(${img})`,
        }}
        alt={title}
      />
      <h2>{title}</h2>
    </div>
  );
}
