import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";

import Logo from "../../assets/images/logo.png";
import { Button } from "@material-ui/core";

import "./style.scss";

function PrivacyPolicy() {
  const { t } = useTranslation();
  const { request } = useHttp();
  const history = useHistory();

  const [privacyPolicy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await request(
        "/terms?type=privacy_policy",
        false,
        "GET"
      );

      setPrivacyPolicy(data?.data);
    })();
  }, []);

  return (
    <div>
      <main className="privacy-policy-container">
        <img className="logo" src={Logo} onClick={() => history.push("/")} />

        <h1>{t("Política de privacidade")}</h1>

        <div className="terms-container">{privacyPolicy}</div>
      </main>
    </div>
  );
}

export default PrivacyPolicy;
