import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";


export function useAuth() {
  const {
    handleSignIn,
    handleSignOut,
    user,
    setUser,
    token,
    isLoggedIn,
  } = useContext(AuthContext);

  return {
    handleSignIn,
    handleSignOut,
    user,
    setUser,
    token,
    isLoggedIn,
  };
}
