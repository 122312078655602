import { IoLocationOutline } from "react-icons/io5";

export function LocationSuggestion({ value }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      value={value}
    >
      <div
        style={{
          width: "48px",
          height: "48px",
          backgroundColor: "#00C2FF",
          color: "#ffffff",
          borderRadius: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IoLocationOutline size="28" />
      </div>
      <p
        style={{
          display: "flex",
          whiteSpace: "nowrap",
          alignItems: "center",
          textAlign: "justify",
          marginLeft: "8px",
        }}
      >
        {value}
      </p>
    </div>
  );
}
