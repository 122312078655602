import { useState, useRef, useEffect } from "react";

import { BalloonWrapper } from "../BalloonWrapper";

import style from "./style.module.scss";

export function Autocomplete({
  suggestions,
  jsxSuggestions,
  inputValue,
  onChange,
  displayMode,
  scrollY,
  ...rest
}) {
  const componentRef = useRef(null);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  let resultsComponent = null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ( componentRef.current && !componentRef.current.contains(event.target) ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value);

    if (jsxSuggestions?.length > 0) {
      setFilteredSuggestions(
        jsxSuggestions.filter(
          (suggestion) =>
            suggestion.props.value.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        )
      );
    } else if (suggestions?.length > 0) {
      setFilteredSuggestions(
        suggestions.filter(
          (suggestion) =>
            suggestion.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        )
      );
    }

    if (filteredSuggestions.length <= 0) return;
    
    setShowSuggestions(true);
    setActiveSuggestionIndex(0);
  };

  const onClick = (e) => {
    setFilteredSuggestions([]);

    onChange(e.currentTarget.innerText);

    setShowSuggestions(false);
    setActiveSuggestionIndex(0);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);

      if (jsxSuggestions?.length > 0) {
        if (!filteredSuggestions[activeSuggestionIndex]) return;
        onChange(filteredSuggestions[activeSuggestionIndex].props.value);
      } else if (suggestions?.length > 0) {
        if (!filteredSuggestions[activeSuggestionIndex]) return;
        onChange(filteredSuggestions[activeSuggestionIndex]);
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) return;

      setActiveSuggestionIndex((old) => old - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) return;

      setActiveSuggestionIndex((old) => old + 1);
    }
  };

  if (showSuggestions && inputValue) {
    if (filteredSuggestions.length) {
      resultsComponent = (
        <ul
          className={style.autocomplete__results}
          style={{
            display: displayMode === "flex" ? "flex" : "grid",
            gridTemplateColumns: displayMode === "flex" ? "1fr" : "1fr 1fr",
            gridTemplateRows: displayMode === "flex" ? "1fr 1fr" : "1fr",
            overflowY: scrollY ? "scroll" : "hidden",
          }}
        >
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            if (index === activeSuggestionIndex) {
              className = style.autocomplete__result__active;
            }

            return (
              <li
                className={className}
                key={index}
                onClick={onClick}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      resultsComponent = (
        <div className={style.autocomplete__no__results}>
          <em>Sem sugestões, você está sozinho!</em>
        </div>
      );
    }
  } else if (inputValue.trim() === "" && showSuggestions) {
    resultsComponent = (
      <ul
        className={style.autocomplete__results}
        style={{
          display: displayMode === "flex" ? "flex" : "grid",
          gridTemplateColumns: displayMode === "flex" ? "1fr" : "1fr 1fr",
          gridTemplateRows: displayMode === "flex" ? "1fr 1fr" : "1fr",
          overflowY: scrollY ? "scroll" : "hidden",
        }}
      >
        {jsxSuggestions?.length > 0
          ? jsxSuggestions.map((suggestion, index) => {
              let className;

              if (index === activeSuggestionIndex) {
                className = style.autocomplete__result__active;
              }

              return (
                <li
                  className={className}
                  key={index}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })
          : suggestions?.length > 0
          ? suggestions.map((suggestion, index) => {
              let className;

              if (index === activeSuggestionIndex) {
                className = style.autocomplete__result__active;
              }

              return (
                <li
                  className={className}
                  key={index}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })
          : null}
      </ul>
    );
  }

  return (
    <div className={style.autocomplete} ref={componentRef}>
      <input
        type="text"
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={inputValue}
        {...rest}
      />
      <BalloonWrapper
        open={showSuggestions}
        scrollY={!!scrollY}
      >
        {resultsComponent}
      </BalloonWrapper>
    </div>
  );
}
