import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Search from "./pages/Search";
import Payment from "./pages/Payment";
import Buffet from "./pages/Buffet";
import RecoverPass from "./pages/RecoverPass";
import Choice from "./pages/Choice";
import NewPass from "./pages/NewPass";
import PassEmail from "./pages/PassEmail";
import "./app.scss";
import SubscriptionCancel from "./pages/SubscriptionCancel";
import DataAccess from "./pages/DataAccess";
import DataForget from "./pages/DataForget";
import DataRectify from "./pages/DataRectify";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EditProfile from "./pages/EditProfile";
import MyRequests from "./pages/MyRequests";
import ReservationDetails from "./pages/ReservationDetails";
import UseTerms from "./pages/UseTerms";
import EmailValidation from "./pages/EmailValidation";
import EmailValidationComplete from "./pages/EmailValidationComplete";

import ScrollToTop from "./hooks/scrollToTop";

import { Toast } from "./components/Toast";
import { AuthProvider } from "./contexts/authContext";
import { SocketProvider } from "./contexts/socket";
import { SearchProvider } from "./contexts/searchContext";
import { ReservationProvider } from "./contexts/reservationContext";
import NotFound from "./pages/NotFound";
import Maintenance from "./pages/Maintenance";
import { Modal } from "./components/Modal";

function App() {
  const [showModal, setShowModal] = React.useState(
    !localStorage.getItem("@user/cookies") ||
      localStorage.getItem("@user/token")
  );

  console.log(`V ${1.2}`);

  const cookiesModal = (
    <Modal
      modalPosition="bottom-left"
      modalSize="small"
      closeIconPosition="right"
      hiddenOverflow
      handleClose={() => {
        setShowModal(false);
        localStorage.setItem("@user/cookies", false);
      }}
      modalStyles={{
        backgroundColor: "#00c2ff",
        color: "#fff",
      }}
      closeBtnStyles={{
        backgroundColor: "#fff",
        color: "#00c2ff",
        borderRadius: "100%",
        width: "20px",
        height: "20px",
        marginRight: "10px",
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.2rem",
      }}
    >
      <div className="modal-header">
        <h3>Cookies e privacidade.</h3>
      </div>
      <div className="modal-body">
        <p>
          Este site usa cookies para melhorar a experiência do utilizador. Ao
          clicar em concordar, aceita a utilização de cookies.
        </p>
        <button
          type="button"
          onClick={() => {
            setShowModal(false);
            localStorage.setItem("@user/cookies", true);
          }}
        >
          Eu concordo
        </button>
      </div>
    </Modal>
  );

  const isUnavailable = false;

  const routes = isUnavailable
    ? [{ path: "*", component: Maintenance }]
    : [
        {
          path: "/",
          component: Home,
        },
        {
          path: "/signup",
          component: Register,
        },
        {
          path: "/signup/email-validation",
          component: EmailValidation,
        },
        {
          path: "/signup/email-validation-complete/:token",
          component: EmailValidationComplete,
        },
        {
          path: "/signin",
          component: Login,
        },
        {
          path: "/search",
          component: Search,
        },
        {
          path: "/edit-profile",
          component: EditProfile,
        },
        {
          path: "/my-requests",
          component: MyRequests,
        },
        {
          path: "/my-requests/:id",
          component: ReservationDetails,
        },
        {
          path: "/payment",
          component: Payment,
        },
        {
          path: "/buffet/:id",
          component: Buffet,
        },
        {
          path: "/recover-password",
          component: RecoverPass,
        },
        {
          path: "/choice",
          component: Choice,
        },
        {
          path: "/new-password/:token",
          component: NewPass,
        },
        {
          path: "/recover-password-email",
          component: PassEmail,
        },
        {
          path: "/client-area/data-access",
          component: DataAccess,
        },
        {
          path: "/client-area/data-forget",
          component: DataForget,
        },
        {
          path: "/client-area/data-rectify",
          component: DataRectify,
        },
        {
          path: "/client-area/subscription-cancel",
          component: SubscriptionCancel,
        },
        {
          path: "/privacy-policy",
          component: PrivacyPolicy,
        },
        {
          path: "/use-terms",
          component: UseTerms,
        },
      ];

  return (
    <AuthProvider>
      <SocketProvider>
        <SearchProvider>
          <ReservationProvider>
            <Router>
              <ScrollToTop />
              {showModal && cookiesModal}
              <Switch>
                {routes.map((route, index) => (
                  <Route key={index} path={route.path} exact>
                    <route.component />
                  </Route>
                ))}
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Router>
            <Toast />
          </ReservationProvider>
        </SearchProvider>
      </SocketProvider>
    </AuthProvider>
  );
}

export default App;
