import { useContext } from "react";
import { SearchContext } from "../contexts/searchContext";

export function useSearch() {
  const context = useContext(SearchContext);
  const {
    search,
    setSearch
  } = context;

  return {
    search,
    setSearch
  };
}
