import "dotenv";
import Logo from "../../assets/images/logo.png";
import RecoverForm from "../../components/RecoverForm";
import ChoiceOption from "../../components/ChoiceOption";
import { useHistory } from "react-router-dom";
import { IoBalloonOutline } from "react-icons/io5";
import { AiOutlineCloud } from "react-icons/ai";
import { ImUserTie } from "react-icons/im";

import "./style.scss";

function RecoverPass() {
  const history = useHistory();

  let buffetAdminPath = process.env.REACT_APP_PROVIDER_PATH;

  return (
    <div className="register-container">
      <div className="cake-image"></div>
      <div className="register-form-container">
        <div className="register-logo-container">
          <img
            onClick={() => {
              history.push("/");
            }}
            style={{ cursor: "pointer", maxHeight: "12em" }}
            src={Logo}
          />
        </div>
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className="choice-question">Para onde quer ser levado?</h1>
          </div>
          <div className="display-options-container">
            <ChoiceOption
              text="Sou proprietário de um buffet"
              icon={<IoBalloonOutline size="35" style={{ color: "#00C2FF" }} />}
              redirectLink={`${buffetAdminPath}/login`}
            />
            {/*<ChoiceOption
              text="Sou prestador de serviço"
              icon={<ImUserTie size="35" style={{ color: "#00C2FF" }} />}
            />*/}
            <ChoiceOption
              text="Sou administrador da Dream Buffets"
              icon={<AiOutlineCloud size="35" style={{ color: "#00C2FF" }} />}
              redirectLink={`${buffetAdminPath}/admin`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecoverPass;
