import style from "./style.module.scss";

function ChoiceOption({ text, icon, redirectLink }) {
  return (
    <div
      className={style.choiceCard}
      onClick={
        redirectLink
          ? () => {
              window.location.href = redirectLink;
            }
          : null
      }
    >
      <div
        style={{
          backgroundColor: "#B4EDFF",
          borderRadius: "50%",
          padding: 10,
          height: 70,
          width: 70,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </div>
      <h2 className={style.cardText}>
        {text}
      </h2>
    </div>
  );
}

export default ChoiceOption;
