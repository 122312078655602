import { useState, useEffect } from "react";
import { useSearch } from "../../hooks/useSearch";
import { useHttp } from "../../hooks/useHttp";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { SearchWithFiltersBar } from "../../components/SearchWithFiltersBar";
import { Dropdown } from "../../components/Dropdown";
import Navbar from "../../components/Navbar";
import SearchItem from "../../components/SearchItem";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Modal,
} from "@material-ui/core";
import { RiEqualizerFill } from "react-icons/ri";
import { Button } from "../../components/Button";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

import style from "./style.module.scss";
import "../../utils/scss/mui.scss";
import { SearchBackground } from "../../components/SearchBackground";

function Search() {
  const isMobile = useMediaQuery({ maxWidth: 930 });
  const history = useHistory();
  const { request } = useHttp();
  const { search, setSearch } = useSearch();

  const [additionalFilters, setAdditionalFilters] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      const { data } = await request("/filter_consumer_search", false, "GET");

      setAdditionalFilters(data.data);
    })();
  }, []);

  async function handleSearch() {
    if (search.seeAll && Object.keys(search).length > 1) {
      setSearch((old) => ({
        ...old,
        seeAll: false,
      }));
    }

    let searchQuery = [];
    let hourQuery = "";
    let capacityQuery = "";

    Object.keys(search).map((key) => {
      if (
        key === "location" ||
        key === "eventType" ||
        key === "cooking" ||
        key === "includedServices" ||
        key === "spaceType"
      ) {
        if (key === "location") {
          searchQuery.push(search[key].split("- ")[1]);
        } else if (
          key === "spaceType" ||
          key === "includedServices" ||
          key === "cooking"
        ) {
          search[key].map((item) => {
            searchQuery.push(item);
          });
        } else {
          searchQuery.push(search[key]);
        }
      } else if (key === "time") {
        hourQuery = `hour=${search[key].split(":")[0]}`;
      } else if (key === "guests") {
        capacityQuery = `buffet_capacity=${Object.values(search[key]).reduce(
          (a, b) => a + b
        )}`;
      }
    });

    const params =
      Object.values(search["guests"]).reduce((a, b) => a + b) === 0 &&
      Object.keys(search).length === 1
        ? ["search="]
        : [
            searchQuery.length ? "search=" + searchQuery.join(" ") + "&" : "",
            hourQuery ? hourQuery + "&" : "",
            capacityQuery > 0 ? capacityQuery : "",
          ].join("");

    const { data } = await request(`/search_buffet?${params}`, false, "GET");

    return data.data;
  }

  useEffect(() => {
    (async () => {
      const data = await handleSearch();

      if (data) setSearchResults(data);
    })();
  }, [search]);

  function handleRemoveFilter(key) {
    if (!search[key]) return;

    if (key === "guests") {
      setSearch((old) => ({
        ...old,
        [key]: {
          adults: 0,
          children: 0,
          babies: 0,
        },
      }));
    } else if (
      key === "cooking" ||
      key === "includedServices" ||
      key === "spaceType"
    ) {
      setSearch((old) => ({
        ...old,
        [key]: [],
      }));
    } else {
      setSearch((old) => ({
        ...old,
        [key]: "",
      }));
    }
  }

  return (
    <div className={style.container}>
      <Navbar />
      {!isMobile && (
        <SearchBackground>
          <h1 className={style.searchEventTitle}>
            {search?.eventType ||
              "Faça sua busca utilizando os filtros abaixo!"}
          </h1>
          <SearchWithFiltersBar />
        </SearchBackground>
      )}
      {isMobile && <hr className={style.accordionDivider} />}
      <section className={style.section}>
        {!isMobile && (
          <div className={style.searchInfo}>
            <div className={style.searchActions}>
              <strong>SUA PESQUISA:</strong>
              <p
                onClick={() => {
                  handleRemoveFilter("eventType");
                  handleRemoveFilter("location");
                  handleRemoveFilter("spaceType");
                  handleRemoveFilter("guests");
                  handleRemoveFilter("time");
                  handleRemoveFilter("date");
                  handleRemoveFilter("cooking");
                  handleRemoveFilter("includedServices");
                }}
              >
                Remover filtros
              </p>
            </div>
            <div
              className={`${style.displayFilters} ${
                Object.values(search["guests"]).reduce(
                  (acc, curr) => acc + curr,
                  0
                ) > 0 ||
                search["time"] ||
                search["date"] ||
                search["location"] ||
                search["eventType"] ||
                search["spaceType"]?.length > 0 ||
                search["cooking"]?.length > 0 ||
                search["includedServices"]?.length > 0
                  ? style.show
                  : ""
              }`}
            >
              {Object.keys(search).map((filter, index) => {
                if (filter === "guests") {
                  if (
                    Object.values(search[filter]).reduce(
                      (acc, curr) => acc + curr,
                      0
                    ) > 0
                  )
                    return (
                      <div key={index} className={style.searchItem}>
                        <span>
                          número de convidados até:{" "}
                          {Object.values(search[filter]).reduce(
                            (acc, curr) => acc + curr,
                            0
                          )}
                        </span>
                        <AiOutlineClose
                          onClick={() => {
                            handleRemoveFilter(filter);
                          }}
                        />
                      </div>
                    );
                } else if (filter === "time") {
                  return (
                    <div key={index} className={style.searchItem}>
                      <span>horário: {search[filter]}</span>
                      <AiOutlineClose
                        onClick={() => {
                          handleRemoveFilter(filter);
                        }}
                      />
                    </div>
                  );
                } else if (filter === "date") {
                  return (
                    <div key={index} className={style.searchItem}>
                      <span>
                        data:{" "}
                        {search[filter].toLocaleDateString("pt-BR", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                      <AiOutlineClose
                        onClick={() => {
                          handleRemoveFilter(filter);
                        }}
                      />
                    </div>
                  );
                } else if (
                  filter === "cooking" ||
                  filter === "includedServices" ||
                  filter === "spaceType"
                ) {
                  return (
                    <div key={index} className={style.searchItem}>
                      <span>
                        {filter === "cooking"
                          ? "cozinha"
                          : filter === "includedServices"
                          ? "serviços inclusos"
                          : "tipo de espaço"}
                        : {search[filter].join(", ")}
                      </span>
                      <AiOutlineClose
                        onClick={() => {
                          handleRemoveFilter(filter);
                        }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className={style.searchItem}>
                      <span>{search[filter]}</span>
                      <AiOutlineClose
                        onClick={() => {
                          handleRemoveFilter(filter);
                        }}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className={style.accordions}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<FaChevronDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={style.typography}>Eventos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {additionalFilters?.events?.map((item) => (
                    <Typography
                      className={style.typography}
                      key={item._id}
                      onClick={() => {
                        setSearch((old) => ({
                          ...old,
                          eventType: item.name,
                        }));
                      }}
                    >
                      <span>{item.name}</span>
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ marginTop: 20 }}>
                <AccordionSummary
                  expandIcon={<FaChevronDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={style.typography}>
                    Número de convidados
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {additionalFilters?.capacity?.map((item) => (
                    <div key={item._id} className={style.listItem}>
                      <input
                        name="number"
                        type="radio"
                        onChange={() =>
                          setSearch((old) => ({
                            ...old,
                            guests: {
                              adults: item.max,
                              children: 0,
                              babies: 0,
                            },
                          }))
                        }
                      />
                      <label>{item.name}</label>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ marginTop: 20 }}>
                <AccordionSummary
                  expandIcon={<FaChevronDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={style.typography}>
                    Tipo de cozinha
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {additionalFilters?.cooking?.map((item) => (
                    <div key={item._id} className={style.listItem}>
                      <input
                        onChange={() => {
                          if (search?.cooking?.includes(item.name)) {
                            setSearch((old) => ({
                              ...old,
                              cooking: search?.cooking.filter(
                                (cook) => cook !== item.name
                              ),
                            }));
                          } else {
                            let newCooking = search?.cooking || [];
                            newCooking.push(item.name);

                            setSearch((old) => ({
                              ...old,
                              cooking: newCooking,
                            }));
                          }
                        }}
                        name="kitchen"
                        type="checkbox"
                        checked={search?.cooking?.includes(item.name) || false}
                      />
                      <label>{item.name}</label>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ marginTop: 20 }}>
                <AccordionSummary
                  expandIcon={<FaChevronDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={style.typography}>
                    Serviços inclusos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {additionalFilters?.service?.map((item) => (
                    <div key={item._id} className={style.listItem}>
                      <input
                        onChange={() => {
                          if (search?.includedServices?.includes(item.name)) {
                            setSearch((old) => ({
                              ...old,
                              includedServices: search?.includedServices.filter(
                                (service) => service !== item.name
                              ),
                            }));
                          } else {
                            let newIncludedServices =
                              search?.includedServices || [];
                            newIncludedServices.push(item.name);

                            setSearch((old) => ({
                              ...old,
                              includedServices: newIncludedServices,
                            }));
                          }
                        }}
                        name="number"
                        type="checkbox"
                        checked={search?.includedServices?.includes(item.name)}
                      />
                      <label>{item.name}</label>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}
        <div className={style.results}>
          <div className={style.resultsCount}>
            <span>
              {searchResults.length > 0 ? searchResults.length : "NENHUM"}{" "}
              RESULTADO(S)
            </span>
            {isMobile && (
              <>
                <button
                  className={style.filtersBtn}
                  type="button"
                  onClick={handleClick}
                >
                  <RiEqualizerFill size="16px" />
                  Filtros
                  <div className={style.count}>
                    {
                      // count how many filters are active considering the search object and its keys
                      Object.keys(search).reduce((acc, key) => {
                        if (search[key] !== undefined) {
                          if (Array.isArray(search[key])) {
                            return acc + search[key].length;
                          }

                          return acc + 1;
                        } else {
                          return acc;
                        }
                      }, 0)
                    }
                  </div>
                </button>
                <Modal
                  id={open ? style.popover : ""}
                  open={open}
                  onClose={handleClose}
                >
                  <div className={`${style.simplePopover} remove__mui__style`}>
                    <button
                      onClick={handleClose}
                      type="button"
                      className={style.closeBtn}
                    >
                      <AiOutlineClose size="22" />
                    </button>
                    <strong>Filtrar</strong>
                    <div className={style.list}>
                      <div className={style.title}>
                        <span>FILTRAR POR:</span>
                        <p
                          onClick={() => {
                            handleRemoveFilter("date");
                            handleRemoveFilter("time");
                            handleRemoveFilter("eventType");
                            handleRemoveFilter("location");
                            handleRemoveFilter("guests");
                            handleRemoveFilter("cooking");
                            handleRemoveFilter("includedServices");
                          }}
                          className={style.removeFiltersBtn}
                        >
                          Remover filtros
                        </p>
                      </div>
                      <Dropdown title="Espaços">
                        {additionalFilters?.events?.map((item) => (
                          <Typography
                            onClick={() => {
                              setSearch((old) => ({
                                ...old,
                                eventType: item.name,
                              }));
                            }}
                            key={item._id}
                            className={style.typography}
                          >
                            {item.name}
                          </Typography>
                        ))}
                      </Dropdown>
                      <hr className={style.accordionDivider} />
                      <Dropdown title="Número de convidados">
                        {additionalFilters?.capacity?.map((item) => (
                          <div key={item._id} className={style.listFlex}>
                            <input
                              name="number"
                              type="radio"
                              onChange={() =>
                                setSearch((old) => ({
                                  ...old,
                                  guests: {
                                    adults: item.max,
                                    children: 0,
                                    babies: 0,
                                  },
                                }))
                              }
                            />
                            <label>{item.name}</label>
                          </div>
                        ))}
                      </Dropdown>
                      <hr className={style.accordionDivider} />
                      <Dropdown title="Topo de cozinha">
                        {additionalFilters?.cooking?.map((item) => (
                          <div key={item._id} className={style.listFlex}>
                            <input
                              onChange={() => {
                                if (search?.cooking?.includes(item.name)) {
                                  setSearch((old) => ({
                                    ...old,
                                    cooking: search?.cooking.filter(
                                      (cook) => cook !== item.name
                                    ),
                                  }));
                                } else {
                                  let newCooking = search?.cooking || [];
                                  newCooking.push(item.name);

                                  setSearch((old) => ({
                                    ...old,
                                    cooking: newCooking,
                                  }));
                                }
                              }}
                              name="kitchen"
                              type="checkbox"
                              checked={
                                search?.cooking?.includes(item.name) || false
                              }
                            />
                            <label>{item.name}</label>
                          </div>
                        ))}
                      </Dropdown>
                      <hr className={style.accordionDivider} />
                      <Dropdown title="Serviços inclusos">
                        {additionalFilters?.service?.map((item) => (
                          <div key={item._key} className={style.listFlex}>
                            <input
                              onChange={() => {
                                if (
                                  search?.includedServices?.includes(item.name)
                                ) {
                                  setSearch((old) => ({
                                    ...old,
                                    includedServices:
                                      search?.includedServices.filter(
                                        (service) => service !== item.name
                                      ),
                                  }));
                                } else {
                                  let newIncludedServices =
                                    search?.includedServices || [];
                                  newIncludedServices.push(item.name);

                                  setSearch((old) => ({
                                    ...old,
                                    includedServices: newIncludedServices,
                                  }));
                                }
                              }}
                              name="number"
                              type="checkbox"
                              checked={search?.includedServices?.includes(
                                item.name
                              )}
                            />
                            <label>{item.name}</label>
                          </div>
                        ))}
                      </Dropdown>
                    </div>

                    <div className={style.popoverActions}>
                      <Button
                        type="button"
                        text={`VER RESULTADOS (${searchResults?.length})`}
                        className={style.seeResultsBtn}
                      />
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </div>
          <div className={style.listResults}>
            {searchResults
              ?.sort((a, b) => b.search_score - a.search_score)
              ?.map((result) => (
                <SearchItem
                  key={result._id}
                  name={result.name}
                  score={result.opinion_score}
                  description={result.description}
                  capacity={{
                    min: result.capacity.min,
                    max: result.capacity.max,
                  }}
                  packageValue={result.package_start_value}
                  district={result.address.district}
                  city={result.address.city}
                  state={result.address.state}
                  image={result.thumbnail_picture}
                  onClick={() => history.push(`/buffet/${result._id}`)}
                />
              ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Search;
