import style from "./style.module.scss";

export function EventStatusBar({
  statusValues,
  standardStatus,
  reservationDay,
  eventDay
}) {
  if (!statusValues) {
    return null;
  }

  const dateDiff = (date1, date2) => {
    const diffTime = date2 - date1;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const daysToEvent = dateDiff(new Date(), new Date(eventDay));

  const findLastIndex = (array, value, key) => {
    let index = -1;
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        index = i;
      }
    }
    return index;
  };

  const firstBar = () => {
    const status = standardStatus.firstBar;
    let index = 0;

    if (findLastIndex(statusValues, 1000, "code") === statusValues.length - 1 || findLastIndex(statusValues, 0, "code")  === statusValues.length - 1) {
      index = 2;
    } else if (daysToEvent < 0) {
      index = 1;
    } else if (findLastIndex(statusValues, 999, "code") === statusValues.length - 1 || findLastIndex(statusValues, 10, "code") === statusValues.length - 1 || findLastIndex(statusValues, 1, "code") === statusValues.length - 1) {
      index = 1;
    } 

    return (
      <div className={style.barOne}>
        <h2 className={`${style.title} ${
          index === 2 ? style.error : ''
        }`}>{status[index].value}</h2>
        <div className={`${style.bar} ${
          index === 2 ? `${style.error} ${style.full}`
          : index === 1 ? style.full
          : style.half
        }`} />
        <p className={style.updatedStatus}>
          {new Intl.DateTimeFormat("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "America/Sao_Paulo"
          }).format(new Date(reservationDay))}
        </p>
      </div>
    )
  }

  const secondBar = () => {
    const status = standardStatus.secondBar;
    let index = -1;

    if (findLastIndex(statusValues, 1000, "code") === statusValues.length - 1 || findLastIndex(statusValues, 0, "code") === statusValues.length - 1) {
      index = 2;
    } else if (findLastIndex(statusValues, 10, "code") === statusValues.length - 1 || findLastIndex(statusValues, 1, "code") === statusValues.length - 1) {
      index = 1;
    } else if (findLastIndex(statusValues, 999, "code") === statusValues.length - 1) {
      index = 0;
    } 

    if ((daysToEvent < 0) && (findLastIndex(statusValues, 1000, "code") === statusValues.length - 1 && findLastIndex(statusValues, 0, "code") === statusValues.length - 1)) {
      index = 1;
    }

    return (
      <div className={style.barTwo}>
        <h2 className={`${style.title} ${
          index === 2 ? style.error : ''
        }`}>{status[index === -1 ? 0 : index].value}</h2>
        <div className={`${style.bar} ${
          index === 2 ? `${style.error} ${style.full}`
          : index === 1 ? style.full
          : index === 0 ? style.half
          : ''
        }`} />
        <p className={style.updatedStatus}>
          {new Intl.DateTimeFormat("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "America/Sao_Paulo"
          }).format(new Date(reservationDay))}
        </p>
      </div>
    )
  }

  const thirdBar = () => {
    const status = standardStatus.thirdBar;
    let index = -1;

    if (findLastIndex(statusValues, 1000, "code") === statusValues.length - 1 || findLastIndex(statusValues, 0, "code") === statusValues.length - 1) {
      index = 2;
    } else if (findLastIndex(statusValues, 1, "code") === statusValues.length - 1) {
      index = 1;
    } else if (findLastIndex(statusValues, 10, "code") === statusValues.length - 1) {
      index = 0;
    }

    if ((daysToEvent < 0) && (findLastIndex(statusValues, 1000, "code") === statusValues.length - 1 && findLastIndex(statusValues, 0, "code") === statusValues.length - 1)) {
      index = 1;
    }

    return (
      <div className={style.barThree}>
        <h2 className={`${style.title} ${
          index === 2 ? style.error : ''
        }`}>{
          index === 2 ? status[index].value
          : daysToEvent > 0 && index !== 1  ? `${daysToEvent} dias até o evento`
          : daysToEvent === 0 ? 'o evento é hoje'
          : status[index === -1 ? 0 : index].value
        }</h2>
        <div className={`${style.bar} ${
          index === 2 ? `${style.error} ${style.full}`
          : index === 1 ? style.full
          : index === 0 ? style.half
          : ''
        }`} />
        <p className={style.updatedStatus}>
          {new Intl.DateTimeFormat("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "America/Sao_Paulo"
          }).format(new Date(eventDay))}
        </p>
      </div>
    )
  }

  return (
    <div className={style.eventStatusBar}>
      {firstBar()}
      {secondBar()}
      {thirdBar()}
    </div>
  );
}
