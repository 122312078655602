import { useState, createContext } from "react";

const SearchContext = createContext({});

function SearchProvider({ children }) {
  const [search, setSearch] = useState({
    guests: {
      adults: 0,
      children: 0,
      babies: 0,
    },
  });

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContext, SearchProvider };
