import { useHistory } from "react-router-dom";

import style from "./style.module.scss";
import ConfirmImage from "../../assets/images/vectors/success-blue.png";

function EmailConfirmation() {
  const history = useHistory()

  return (
    <div>
      <form className={style.emailConfirmationForm}>
        <div className={style.checkImg}>
          <img src={ConfirmImage} />
        </div>
        <h1>Verifique seu e-mail</h1>
        <p className={style.instruction}>Enviamos instruções de recuperação de senha para o seu e-mail.</p>

        <div className={style.actions}>
          <p>
            Não recebeu o e-mail? Verifique a sua caixa de spam,&nbsp;

            <span onClick={() => {history.push("/recover-password")}}>
              ou tente outro endereço de e-mail.
            </span>
          </p>
        </div>
      </form>
    </div>
  );
}

export default EmailConfirmation;
