import { AiFillStar } from "react-icons/ai";
import style from "./style.module.scss";

function PaymentComponent({
  name,
  score,
  picture,
  totalOpinions,
  packageValue,
  discount,
  total
}) {
  return (
    <div className={style.container}>
      <div className={style.section}>
        <div
          className={style.picture}
          style={{
            backgroundImage: picture && `url(${picture})`,
          }}
        />
        <div className={style.score}>
          <p style={{ fontWeight: "bold" }}>{name && name}</p>
          <span style={{ display: "flex", alignItems: "center" }}>
            <AiFillStar color={score >= 1 ? "yellow" : "#d3d3d3"} size={18} />
            <AiFillStar color={score >= 2 ? "yellow" : "#d3d3d3"} size={18} />
            <AiFillStar color={score >= 3 ? "yellow" : "#d3d3d3"} size={18} />
            <AiFillStar color={score >= 4 ? "yellow" : "#d3d3d3"} size={18} />
            <AiFillStar color={score >= 5 ? "yellow" : "#d3d3d3"} size={18} />
            <span style={{ marginLeft: 10, color: "#9D9D9D" }}>
              {totalOpinions && totalOpinions}
            </span>
          </span>
        </div>
      </div>
      <hr style={{ backgroundColor: "#cccccc", margin: "20px 0" }} />
      <div style={{ width: "100%", display: "flex" }}>
        <div className={style.info}>
          <h3>Informações de preço</h3>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              margin: "6px 0",
            }}
          >
            <span style={{ color: "#7C7C7C", fontSize: 18 }}>Pacote</span>
            <span style={{ color: "#7C7C7C", fontSize: 18 }}>{
              packageValue ? packageValue.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }) : 'R$ 0,00'
            }</span>
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              margin: "6px 0",
            }}
          >
            <span style={{ color: "#7C7C7C", fontSize: 18 }}>
              Desconto do buffet
            </span>
            <span style={{ color: "#7C7C7C", fontSize: 18 }}>{discount ? `${discount}%` : '0%'}</span>
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              margin: "6px 0",
            }}
          >
            <span style={{ color: "#000", fontWeight: "bold", fontSize: 18 }}>
              Total (BRL)
            </span>
            <span style={{ color: "#000", fontWeight: "bold", fontSize: 18 }}>
              {total ? total.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }) : 'R$ 0,00'}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PaymentComponent;
