import { useHistory } from "react-router-dom";

import RecoverForm from "../../components/RecoverForm";

import style from "./style.module.scss";
import logo from "../../assets/images/logo.png";
import sideImg from "../../assets/images/recover.png";

function PassEmail() {
  const history = useHistory();

  return (
    <div className={style.container}>
      <aside
        className={style.sideImg}
        style={{ backgroundImage: `url(${sideImg})` }}
      />
      <div className={style.recoverContainer}>
        <div className={style.logo}>
          <img
            onClick={() => {
              history.push("/");
            }}
            src={logo}
          />
        </div>
        <RecoverForm />
      </div>
    </div>
  );
}

export default PassEmail;
