import { RatingComponent } from "../RatingComponent";

import style from "./style.module.scss";

export function BuffetCard({
  companyPhoto,
  companyType,
  companyName,
  companyCity,
  companyState,
  companyPackageValue,
  companyCapacityMin,
  companyCapacityMax,
  companyScore,
  companyTotalOpinions,
  onClick,
}) {
  return (
    <div
      className={style.companyCard}
      onClick={onClick && onClick}
      style={{
        boxShadow: "0px 4px 14px 4px rgba(0, 0, 0, 0.1)",
        cursor: onClick ? "pointer" : "auto",
      }}
    >
      <svg width="0" height="0">
        <linearGradient id="half-star-color">
          <stop stopColor="yellow" offset="50%" />
          <stop stopColor="#e5e5e5" offset="50%" />
        </linearGradient>
      </svg>

      <div className={style.companyCardImg}>
        <img src={companyPhoto} alt='Imagem do Buffet' />
      </div>
      <div className={style.cardContainer}>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span className={style.cardText}>{companyType}</span>
          <h3>{companyName}</h3>
        </div>
        <div
          className={style.cardRating}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RatingComponent value={companyScore} readOnly />
          <span className={style.cardText} style={{ marginLeft: 10 }}>
            {companyTotalOpinions}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid #A1A1A1",
            padding: "10px 0 ",
          }}
        >
          <span className={style.cardText} style={{ marginLeft: 10 }}>
            {companyCity}, {companyState}
          </span>
        </div>

        <div className={style.cardFooter}>
          <div className={style.footerItem}>
            <span className={style.itemLabel}>PACOTES A PARTIR DE</span>{" "}
            <span className={style.itemValue}>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(companyPackageValue)}
            </span>
          </div>
          <div className={style.footerItem}>
            <span className={style.itemLabel}>CAPACIDADE</span>
            <span className={style.itemValue}>
              {companyCapacityMin === 500 ? "500+" : companyCapacityMin + "-" + companyCapacityMax}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
