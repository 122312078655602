import style from "./style.module.scss";

export function BalloonWrapper({
  open,
  scrollY,
  customStyles,
  children,
  refValue,
  ...rest
}) {
  return open ? (
    <div
      ref={refValue}
      className={style.balloon}
      style={{
        overflowY: scrollY ? "scroll" : "visible",
        ...customStyles,
      }}
      {...rest}
    >
      {children}
    </div>
  ) : null;
}
