import { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { GoPrimitiveDot } from "react-icons/go";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";

import visa from "../../assets/images/vectors/visa.png";
import mastercard from "../../assets/images/vectors/mastercard.png";
import amex from "../../assets/images/vectors/amex.png";
import elo from "../../assets/images/vectors/elo.png";
import hipercard from "../../assets/images/vectors/hipercard.png";
import dinersclub from "../../assets/images/vectors/dinersclub.png";

import "./style.scss";
import { useReservations } from "../../hooks/useReservations";
import { GrStatusUnknown } from "react-icons/gr";

function MyRequests() {
  const history = useHistory();

  const { getAllUserReservations, reservations } = useReservations();

  const isMobile = !useMediaQuery({ maxWidth: 930 });

  const cardImages = [
    {
      name: "visa",
      image: visa,
    },
    {
      name: "mastercard",
      image: mastercard,
    },
    {
      name: "amex",
      image: amex,
    },
    {
      name: "elo",
      image: elo,
    },
    {
      name: "hipercard",
      image: hipercard,
    },
    {
      name: "dinersclub",
      image: dinersclub,
    },
  ];

  const thList = [
    "ID do pedido",
    "Buffet contratado",
    "Data e hora",
    "Forma de pagamento",
  ];

  useEffect(() => {
    (async () => {
      await getAllUserReservations();
    })();
  }, []);

  const timePeriod = (period) => {
    switch (period) {
      case "morning":
        return "manhã";
      case "afternoon":
        return "tarde";
      case "night":
        return "noite";
      default:
        break;
    }
  };

  return (
    <>
      <Navbar />
      <main className="my-requests-content">
        {isMobile && <Sidebar />}
        <div className="container">
          {reservations ? (
            <table className="request-info-container">
              <thead>
                <tr>
                  {thList.map((th, index) => (
                    <th key={index}>{th}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reservations?.map((reservation) => (
                  <tr key={reservation.id}>
                    <td>{reservation.requestID}</td>
                    <td>{reservation.buffet}</td>
                    <td>
                      {new Date(reservation.date).toLocaleDateString("pt-BR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}{" "}
                      - {timePeriod(reservation.hour)}
                    </td>
                    <td>
                      {reservation.paymentMethod.method === "credit-card" && (
                        <div className="credit-card-info">
                          <div className="credi-card-numbers">
                            <div
                              className={`credit-card-container ${
                                reservation.paymentMethod?.card_type.toLowerCase() ===
                                  "diners_club" ||
                                reservation.paymentMethod?.card_type.toLowerCase() ===
                                  "amex" ||
                                reservation.paymentMethod?.card_type.toLowerCase() ===
                                  "visa"
                                  ? "lightBg"
                                  : ""
                              }`}
                            >
                              {reservation.paymentMethod?.card_type ===
                              "unknown" ? (
                                <GrStatusUnknown size="1rem" />
                              ) : (
                                <img
                                  src={
                                    cardImages.find(
                                      (card) =>
                                        card.name ===
                                        reservation.paymentMethod?.card_type.toLowerCase()
                                    )?.image
                                  }
                                  alt={reservation.paymentMethod?.card_type}
                                />
                              )}
                            </div>
                            <div className="credit-card-hidden-numbers">
                              <GoPrimitiveDot size={18} />
                              <GoPrimitiveDot size={18} />
                              <GoPrimitiveDot size={18} />
                              <GoPrimitiveDot size={18} />
                            </div>
                            {reservation.paymentMethod?.last_digit}
                          </div>
                          <button
                            onClick={() =>
                              history.push(`/my-requests/${reservation.id}`)
                            }
                            className="request-button"
                          >
                            {isMobile ? (
                              "VER DETALHES"
                            ) : (
                              <HiOutlineDotsCircleHorizontal size={25} />
                            )}
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no-requests">Nenhum pedido encontrado...</div>
          )}
        </div>
      </main>
    </>
  );
}

export default MyRequests;
