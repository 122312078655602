import style from './style.module.scss'
import groupChatting from '../../assets/images/groupChatting.svg'
import logo from '../../assets/images/logo.png'
import { Button } from '../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from "react-router-dom";

function NotFound () {
  const isMobile = useMediaQuery({ maxWidth: 990 });
  const history = useHistory();

  return (
    <div className={style.container}>
      {isMobile && <img src={logo} alt='Dream Buffets' className={style.logo} />}
      <div className={style.imgContainer}>
        <img src={groupChatting} alt="Grupo Conversando" />
      </div>
      <div className={style.pageContent}>
        {!isMobile && <img src={logo} alt='Dream Buffets' className={style.logo} />}
        <h1>Oops!</h1>
        <p>A página que você está procurando não existe ou está indisponível no momento. Tente novamente mais tarde.</p>

        <Button
          type="button"
          text="Voltar para o início"
          onClickFunction={() => history.push("/")}
          className={style.btn}
        />
      </div>
    </div>
  )
}

export default NotFound;
