import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";
import { useToast } from "../../hooks/useToast";

import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Button } from "../Button";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import ReactInputMask from "react-input-mask";

import style from "./style.module.scss";

function hasAllCases(value) {
  const upper = /[A-Z]/.test(value),
    lower = /[a-z]/.test(value);
  return upper && lower;
}
function passwordIsValid(password) {
  return password?.length >= 6 && hasAllCases(password);
}

function emailCheck(email) {
  if (!email) return false;
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

function checkCode(code) {
  if (!code) return false;
  code = code.replace(/[^\d]+/g, "");
  if (code === "") return false;
  if (code.length !== 11 || /^(.)\1+$/.test(code)) return false;

  // verificar se cpf e valido
  let sum = 0;
  let rest;
  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(code.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(code.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(code.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(code.substring(10, 11))) return false;
  return true;
}

function checkPhone(phone) {
  if (!phone) return false;
  phone = phone.replace(/[^\d]+/g, "");
  if (phone === "") return false;
  return /^\d{11}$/.test(phone);
}

function checkName(name) {
  if (!name) return false;
  // const re = /^[a-zA-Z]+\s[a-zA-Z]+$/;
  const re = /[A-Z][a-z]* [A-Z][a-z]*/;
  return re.test(name);
}

const lockBtn = (value) => {
  console.log(value);
  console.log(
    checkName(value.name) &&
      checkPhone(value.phone) &&
      checkCode(value.code) &&
      emailCheck(value.email) &&
      passwordIsValid(value.password) &&
      value.password === value.confirmPassword &&
      value.birthdate?.length > 1 &&
      value.city !== "default" &&
      value.state !== "default"
  );

  console.log(
    checkName(value.name),
    checkPhone(value.phone),
    checkCode(value.code),
    emailCheck(value.email),
    passwordIsValid(value.password),
    value.password === value.confirmPassword,
    value.birthdate?.length > 1,
    value.city !== "default",
    value.state !== "default"
  );

  if (!value) return true;
  else if (
    checkName(value.name) &&
    checkPhone(value.phone) &&
    checkCode(value.code) &&
    emailCheck(value.email) &&
    passwordIsValid(value.password) &&
    value.password === value.confirmPassword &&
    value.birthdate?.length > 1 &&
    value.city !== "default" &&
    value.state !== "default"
  )
    return false;

  return true;
};

function RegisterForm() {
  const { request } = useHttp();
  const { notify } = useToast();
  const history = useHistory();

  const [user, setUser] = useState({
    city: "default",
    state: "default",
  });
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [seePassword, setSeePassword] = useState(false);

  useEffect(() => {
    const options = document.querySelectorAll("option");
    options.forEach((option) => {
      if (option.classList.contains("selected")) {
        if (option.innerText.includes("*")) return;
        option.innerHTML = option.innerHTML + " *";
      } else {
        if (option.innerText === "Estado *" || option.innerText === "Cidade *")
          return;
        option.innerHTML = option.innerHTML.replace(" *", "");
      }
    });
  }, [user]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/state_name", false, "GET");

      setStatesList(data.data);
    })();
  }, []);

  async function handleGetCities(id) {
    const { data } = await request(`/city_name?id=${id}`, false, "GET");
    setCitiesList(data.data);
  }

  async function handleCreateUser(event) {
    event.preventDefault();

    try {
      const body = {
        ...user,
        phone: user.phone.replace(/[^0-9 ]/g, ""),
        code: user.code.replace(/[^0-9 ]/g, ""),
        birthdate: new Date(
          user.birthdate.split("/").reverse().join("/")
        ).getTime(),
      };

      const { data } = await request("/people", false, "POST", body);

      if (data.status) {
        localStorage.setItem("register/email", user.email);

        history.push("/signup/email-validation");
      } else {
        notify(
          "Erro ao cadastrar usuário",
          `Erro ao cadastrar o usuário ${user.name}, CPF e e-mail não podem estar associados à outra conta!`,
          "error"
        );
      }
    } catch (err) {
      notify("Erro ao cadastrar usuário", "Erro desconhecido", "error");
    }
  }

  return (
    <div>
      <form onSubmit={handleCreateUser} className={style.container}>
        <h1>cadastre-se</h1>
        <div className={style.input}>
          <TextField
            className={style.textField}
            variant="standard"
            required
            label="Nome Completo"
            value={user?.name || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, name: e.target.value }))
            }
          />
        </div>
        <div className={style.input}>
          <div>
            <select>
              <option value="default" selected style={{ color: "#ededed" }}>
                Brasil
              </option>
            </select>
          </div>
        </div>
        <div className={style.input}>
          <div>
            <select
              value={user?.state}
              onChange={async (e) => {
                setUser((old) => ({ ...old, state: e.target.value }));

                if (e.target.value !== "" && e.target.value !== "default") {
                  await handleGetCities(e.target.value);
                }
              }}
            >
              <option value="default" selected style={{ color: "#ededed" }}>
                Estado *
              </option>
              {statesList.map((state) => (
                <option
                  value={state._id}
                  className={state._id === user?.state ? "selected" : ""}
                >
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <span className={style.instruction}>
          Por favor, primeiro selecione um estado, depois uma cidade.
        </span>
        <div className={style.input}>
          <div>
            <select
              disabled={citiesList.length <= 0}
              value={user?.city}
              onChange={(e) => {
                setUser((old) => ({ ...old, city: e.target.value }));
              }}
              className={style.selectCities}
            >
              <option value="default" selected style={{ color: "#ededed" }}>
                Cidade *
              </option>
              {citiesList.map((city) => (
                <option
                  value={city._id}
                  className={city._id === user?.city ? "selected" : ""}
                >
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={style.input}>
          <ReactInputMask
            value={user?.phone || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, phone: e.target.value }))
            }
            mask="(99) 99999-9999"
          >
            {() => (
              <TextField
                className={style.textField}
                variant="standard"
                label="Telefone celular"
                required
              />
            )}
          </ReactInputMask>
        </div>
        <div className={style.input}>
          <ReactInputMask
            value={user?.birthdate || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, birthdate: e.target.value }))
            }
            mask="99/99/9999"
          >
            {() => (
              <TextField
                className={style.textField}
                variant="standard"
                label="Data de nascimento"
                required
              />
            )}
          </ReactInputMask>
        </div>
        <div className={style.input}>
          <ReactInputMask
            value={user?.code || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, code: e.target.value }))
            }
            mask="999.999.999-99"
          >
            {() => (
              <TextField
                className={style.textField}
                variant="standard"
                label="CPF"
                required
              />
            )}
          </ReactInputMask>
        </div>
        <div className={style.input}>
          <TextField
            className={style.textField}
            variant="standard"
            label="E-mail"
            value={user?.email || ""}
            required
            onChange={(e) =>
              setUser((old) => ({ ...old, email: e.target.value }))
            }
          />
        </div>
        <div className={style.input}>
          <TextField
            className={style.textField}
            variant="standard"
            label="Senha"
            type={seePassword ? "text" : "password"}
            error={!passwordIsValid(user?.password)}
            helperText="A Senha deve ter no mínimo 6 caracteres e possuir letras maiúsculas e minúsculas."
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setSeePassword((old) => !old)}
                  >
                    {seePassword ? <RiEyeFill /> : <RiEyeCloseFill />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user?.password || ""}
            required
            onChange={(e) =>
              setUser((old) => ({ ...old, password: e.target.value }))
            }
          />
        </div>
        <div className={style.input}>
          <TextField
            variant="standard"
            label="Confirme sua senha"
            className={style.textField}
            type={seePassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setSeePassword((old) => !old)}
                  >
                    {seePassword ? <RiEyeFill /> : <RiEyeCloseFill />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user?.confirmPassword || ""}
            onChange={(e) =>
              setUser((old) => ({ ...old, confirmPassword: e.target.value }))
            }
            error={passwordConfirm !== user?.password && passwordConfirm !== ""}
            helperText="As senhas devem ser iguais!"
            required
          />
        </div>
        <div className={style.policyTerms}>
          <p>
            Ao clicar em Concordar e continuar, concordo com os
            <a href="/use-terms" target="_blank" rel="noopener noreferrer">
              &nbsp;Termos de Serviço
            </a>
            &nbsp;e com a&nbsp;
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              &nbsp;Política de Privacidade
            </a>
            &nbsp;da Dream Buffets.
          </p>
        </div>
        <div className={style.actions}>
          <Button
            type="submit"
            text="CONCORDAR E CONTINUAR"
            disabled={lockBtn(user)}
            className={lockBtn(user) ? style.disabled : style.registerBtn}
          />
        </div>
        <p className={style.moreActions}>
          Já tem uma conta?{" "}
          <span
            onClick={() => {
              history.push("/signin");
            }}
          >
            {" "}
            Entrar
          </span>
        </p>
      </form>
    </div>
  );
}

export default RegisterForm;
