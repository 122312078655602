import { Button as MaterialButton } from '@mui/material'
import { Spinner } from '../Spinner'

export function Button ({ disabled, customStyles, text, loading, type, onClickFunction, ...rest }) {
  return (
    <MaterialButton
      {...rest}
      className={
        rest.className ? rest.className : ''
      }
      type={type}
      onClick={onClickFunction}
      disabled={disabled || loading}
      style={{ ...customStyles }}
    >
      {loading ? <Spinner customStyles={{ width: '1.5rem', height: '1.5rem', borderTopColor: '#92E5FF' }} /> : text}
    </MaterialButton>
  )
}
